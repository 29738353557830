import { useState } from "react";
import { useFormData } from "utils/hooks/useFormData";
import useRequest from "utils/hooks/useRequest";

export default function useWithdrawal(
   withdrawService,
   id = "",
   mutateFn,
   setModalDisplay,
) {
   //form states
   const initialState = { amount: "", formattedAmount: "" };
   const [state, dispatch] = useFormData(initialState);
   const [pin, setPin] = useState("");

   //modal states
   const [successModal, setSuccessModal] = useState(false);
   const [numpadModal, setNumpadModal] = useState(false);
   const [errorModal, setErrorModal] = useState(false);
   const [amountModal, setAmountModal] = useState(false);

   const toggleSuccessModal = () => setSuccessModal((prev) => !prev);
   const toggleNumpadModal = () => setNumpadModal((prev) => !prev);
   const toggleErrorModal = () => setErrorModal((prev) => !prev);
   const toggleAmountModal = () => setAmountModal((prev) => !prev);

   //other states
   const [errorMessage, setErrorMessage] = useState("");
   const [errorBtnText, setErrorBtnText] = useState("try again");

   //queries
   const { queryFn: withdrawFn, loading: withdrawLoading } = useRequest(
      withdrawService,
      id,
   );

   //handle functions
   const handleNext = () => {
      setModalDisplay(false);
      setAmountModal(true);
   };

   const handleClick = () => {
      setAmountModal(false);
      setNumpadModal(true);
   };

   const handleSubmit = () => {
      setNumpadModal(false);
      const queryObj = { amount: state.amount, transaction_pin: pin };
      withdrawFn(queryObj, {
         onSuccess: () => {
            setSuccessModal(true);
            mutateFn();
         },
         onError: (message) => {
            if (message.includes("pin you entered is incorrect!")) {
               setErrorMessage(
                  `the pin you entered is incorrect \nPlease check your transaction pin and try again`,
               );
            } else if (message.includes("do not have sufficient balance")) {
               setErrorMessage(
                  `you do not have sufficient balance to process this request`,
               );
               setErrorBtnText("back");
            } else {
               setErrorMessage(
                  `payout request could not be completed\nplease check your network settings and try again`,
               );
            }
            setErrorModal(true);
         },
      });
      setPin("");
      dispatch({ type: "CLEAN_SLATE", payload: initialState });
   };

   return {
      pin,
      setPin,
      state,
      dispatch,
      errorBtnText,
      errorMessage,
      withdrawLoading,
      successModal,
      numpadModal,
      errorModal,
      amountModal,
      toggleAmountModal,
      toggleErrorModal,
      toggleNumpadModal,
      toggleSuccessModal,
      handleNext,
      handleSubmit,
      handleClick,
   };
}
