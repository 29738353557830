import UserDetails from './user-details';
import TransactionDetails from './transaction-details';

export default function Transfer({
   step,
   optionsObj,
   pageName,
   selectLabel,
   transferType,
   state,
   dispatch,
   handleChange,
   handleSubmit,
   formValidation
}) {
   switch (step) {
      case 2: {
         return (
            <TransactionDetails
               state={state}
               dispatch={dispatch}
               optionsObj={optionsObj}
               selectLabel={selectLabel}
               handleChange={handleChange}
               handleSubmit={handleSubmit}
               formValidation={formValidation}
            />
         );
      }

      default: {
         return (
            <UserDetails
            state={state}
            dispatch={dispatch}
            handleChange={handleChange}
            transferType={transferType}
            pageName={pageName}
            formValidation={formValidation}
         />
         );
      }
   }
}
