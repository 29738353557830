import Button from "../forms/button";
import ModalContainer from "../modals/modal-container";

export default function FailModal({
   src,
   title = "something went wrong",
   btnText = "try again",
   to,
   href,
   btnOnClick,
   modalDisplay,
   toggleModal,
   modalPosition,
   message = `this process could not be completed. 
   Please check your network settings and try again`,
}) {
   return (
      <ModalContainer
         area="THIN"
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
         modalPosition={modalPosition}
      >
         <div className="container justify-center md:py-24">
            <img
               src={src || "/icons/carbon_retry-failed.png"}
               alt="Icon"
               className="mx-auto mb-4 w-28"
            />

            <h1 className="text-2xl font-bold capitalize text-secondary-darkest">
               {title}
            </h1>

            <p className="mt-1 whitespace-pre-wrap text-center text-secondary-darkest md:text-sm">
               {message}
            </p>

            <div className="mt-14">
               <Button
                  to={to}
                  href={href}
                  color="RED"
                  size="WIDE"
                  onClick={!to ? btnOnClick : undefined}
               >
                  <span className="px-10">{btnText}</span>
               </Button>
            </div>
         </div>
      </ModalContainer>
   );
}
