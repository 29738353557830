import { useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { IoArrowBack } from "react-icons/io5";
import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import ModalContainer from "components/shared/modals/modal-container";
import PageTitle from "components/shared/layout/headers/page-title";
import Spinner from "components/shared/feedbacks/spinner";
import useInputUpdate from "utils/hooks/useInputUpdate";
import {useNavigate} from "react-router-dom";

export default function EnterPassword({
   modalDisplay,
   toggleModal,
   state,
   dispatch,
   loading,
   btnText,
   handleSubmit,
   formValidation,
}) {

   const navigate = useNavigate();
   const [showPassword, setShowPassword] = useState(false);

   const handleChange = useInputUpdate(dispatch);

   return (
      <ModalContainer modalDisplay={modalDisplay} toggleModal={toggleModal}>
           <IoArrowBack
                  onClick={() =>  navigate("../../../dashboard/settings")}
                  className="fixed top-10 left-4 w-12 cursor-pointer text-4xl md:hidden md:w-8"
               />
         <div className="container pt-24 md:py-12">
            <PageTitle
               variant="TERTIARY"
               title="enter password"
               message="please enter your ledga account password"
            />
            <form className="self-stretch mb-10" autoComplete="new-password">
               <input type="hidden" value="something" />
               <Input
                  value={state.password}
                  name="password"
                  label="password"
                  iconPosition="RIGHT"
                  showPassword={showPassword}
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  iconOnClick={() => setShowPassword((prev) => !prev)}
                  icon={
                     showPassword ? (
                        <IoEyeOffOutline className="text-2xl" />
                     ) : (
                        <IoEyeOutline className="text-2xl" />
                     )
                  }
                  errorMessage={{
                     requiredError: `password must be at least 8 digits long,\ncontain at least 1 uppercase & lowercase character`,
                     validityError: `password must be at least 8 digits long,\ncontain at least 1 uppercase & lowercase character`,
                  }}
                  isValid={formValidation.password}
               />
            </form>
            <Button disabled={loading} size="WIDE" onClick={handleSubmit}>
               {loading ? <Spinner color="WHITE" /> : btnText}
            </Button>
         </div>
      </ModalContainer>
   );
}
