import { useState, useCallback } from "react";
import { useFormData } from "utils/hooks/useFormData";
import PersonalDetailsForm from "components/setup/personal-details-form";
import BankDetailsForm from "components/setup/bank-details-form";
import CreatePasswordForm from "components/setup/create-password-form";
import IDCreatedModal from "components/shared/feedbacks/id-created-modal";
import LoadingModal from "components/shared/feedbacks/loading-modal";
import CreatePinModal from "components/setup/create-pin-modal";
import SetupCompleteModal from "components/setup/setup-complete-modal";
import useFormValidation from "utils/hooks/useFormValidation";
import { completeSignup } from "api/services/auth.service";
import useRequest from "utils/hooks/useRequest";
import FailModal from "components/shared/feedbacks/failure-modal";

export default function SetUp() {
   const initialFormData = {
      password: "",
      passwordConfirmation: "",
      firstName: "",
      lastName: "",
      address: "",
      accountNumber: "",
      accountName: "",
      bankName: "",
      bvn: "",
      dateOfBirth: "",
   };

   //form state
   const [state, dispatch] = useFormData(initialFormData);
   const { formValidation, formIsValid } = useFormValidation(state);

   //modals state
   const [idCreatedModal, setIDCreatedModal] = useState(false);
   const [createPinModal, setCreatePinModal] = useState(false);
   const [setupCompleteModal, setSetupCompleteModal] = useState(false);
   const [errorModal, setErrorModal] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [errorTitle, setErrorTitle] = useState("");
   const [errorBtnText, setErrorBtnText] = useState("try again");
   const [errorBtnLink, setErrorBtnLink] = useState(null);

   //prop states and others
   const [screen, setScreen] = useState(1);
   const [merchantId, setMerchantId] = useState();

   const { queryFn: setupFn, loading: setupLoading } =
      useRequest(completeSignup);

   const handleFormSubmit = () => {
      const queryObj = {
         firstname: state.firstName,
         address: state.address,
         account_name: state.accountName,
         account_number: state.accountNumber,
         bank_code: state.bankCode,
         bank_name: state.bankName,
         bvn: state.bvn,
         password: state.password,
         lastname: state.lastName,
         date_of_birth: state.dateOfBirth,
      };
      if (formIsValid) {
         setupFn(queryObj, {
            onSuccess: (data) => {
               setMerchantId(data);
               setIDCreatedModal(true);
            },
            onError: (message) => {
               if (message.includes("account has already been setup")) {
                  setErrorMessage(`${message} Please signin to continue`);
                  setErrorTitle("Setup Already Completed");
                  setErrorBtnText("sign in");
                  setErrorBtnLink("../signin");
               }
               setErrorModal(true);
            },
         });
      }
   };

   const handleNext = () => {
      setIDCreatedModal(false);
      setCreatePinModal(true);
   };

   //wrapped in useCallback because it is called in
   //a useEffect useBankDetails hook and used as dependency
   const handleBankFormError = useCallback(
      (
         message = `Could not fetch bank details.
      Please check your network and try again`,
         title = "something went wrong",
      ) => {
         setErrorModal(true);
         setErrorMessage(message);
         setErrorTitle(title);
      },
      [],
   );

   const handleRetry = () => {
      setErrorModal(false);
   };

   const handleFinish = () => {
      setCreatePinModal(false);
      setSetupCompleteModal(true);
   };

   switch (screen) {
      case 3: {
         return (
            <>
               <BankDetailsForm
                  setup={true}
                  setScreen={setScreen}
                  state={state}
                  dispatch={dispatch}
                  handleSubmit={handleFormSubmit}
                  btnText="finish"
                  message="Setup your account for receiving payouts"
                  splashscreen={true}
                  formValidation={formValidation}
                  handleError={handleBankFormError}
               />
               <LoadingModal
                  modalPosition="CENTER"
                  modalDisplay={setupLoading}
                  message="Wait a moment while we create your unique Student ID"
               />
               <IDCreatedModal
                  id={merchantId}
                  modalPosition="CENTER"
                  modalDisplay={idCreatedModal}
                  message={`Your unique \n Student ID has been created`}
                  handleClick={handleNext}
               />
               <CreatePinModal
                  modalPosition="CENTER"
                  createPinModal={createPinModal}
                  btnText="proceed"
                  successBtnOnClick={handleFinish}
                  setCreatePinModal={setCreatePinModal}
               />
               <SetupCompleteModal
                  modalPosition="CENTER"
                  modalDisplay={setupCompleteModal}
               />
               <FailModal
                  modalPosition="CENTER"
                  modalDisplay={errorModal}
                  title={errorTitle}
                  to={errorBtnLink}
                  message={errorMessage.toString()}
                  btnText={errorBtnText}
                  btnOnClick={handleRetry}
               />
            </>
         );
      }
      case 2: {
         return (
            <PersonalDetailsForm
               setScreen={setScreen}
               state={state}
               dispatch={dispatch}
               splashscreen="true"
               formValidation={formValidation}
            />
         );
      }
      default:
         return (
            <CreatePasswordForm
               setScreen={setScreen}
               state={state}
               dispatch={dispatch}
               formValidation={formValidation}
            />
         );
   }
}
