import { axios } from "./axios";

export const fetcher = (url) =>
   axios.get(url).then((res) => {
      if (res.data.status === "error") {
         const error = new Error(res.data.message);
         throw error;
      } else {
         return res.data.data;
      }
   });
