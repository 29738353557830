import * as REGEX from '../constants/regex';

export default function useFormValidation(state) {
   const validator = {
      accountName: true,
      accountNumber: REGEX.ACCOUNT_NUMBER_VALIDATION.test(state.accountNumber),
      address: REGEX.ADDRESS_VALIDATION.test(state.address),
      amount: REGEX.AMOUNT_VALIDATION.test(state.amount),
      bankCode: state.bankCode?.length > 1,
      bankName: state.bankName?.length > 3,
      bvn: REGEX.BVN_VALIDATION.test(state.bvn),
      country: REGEX.NAME_VALIDATION.test(state.country),
      dateOfBirth: REGEX.BIRTH_DATE_VALIDATION.test(state.dateOfBirth),
      email: REGEX.EMAIL_VALIDATION.test(state.email),
      firstName: REGEX.FULL_NAME_VALIDATION.test(state.firstName),
      formattedAmount: true,
      id: REGEX.ADDRESS_VALIDATION.test(state.id),
      lastName: REGEX.FULL_NAME_VALIDATION.test(state.lastName),
      name: REGEX.FULL_NAME_VALIDATION.test(state.name),
      password: REGEX.PASSWORD_VALIDATION.test(state.password),
      passwordConfirmation: state.password === state.passwordConfirmation,
      paymentPurpose: state.paymentPurpose?.length > 2,
      phoneCode: state.phoneCode?.length > 1,
      phoneNumber: REGEX.PHONE_NUMBER_VALIDATION.test(state.phoneNumber),
      storeName: REGEX.FULL_NAME_VALIDATION.test(state.storeName),
      ownerName: REGEX.FULL_NAME_VALIDATION.test(state.ownerName),
   };
   /*loop through names of items in state, enter those names into new object,
      assign the values of the equivalent name in validator object.
      the purpose is to essentially filter into the formValidation object, 
      only those validators that are present in the current state object */
   const formValidation = {};
   Object.keys(state).forEach(
      (item) => (formValidation[item] = validator[item]),
   );

   //check if the value of every item in formValidation
   //is true
   const formIsValid = Object.values(formValidation).every((e) => e === true);

   return { formValidation, formIsValid };
}
