import { useEffect } from 'react';
import { useRef } from 'react';

export default function Modal({
   className,
   background,
   modalDisplay,
   toggleModal,
   children,
   modalPosition,
}) {
   //styles
   const modalWrapperClass = `${
      modalDisplay ? 'block' : 'hidden'
   } fixed z-20 top-0 left-0 w-full h-full bg-black bg-opacity-25`;
   const modalClass = `${className} ${
      background === 'blue'
         ? 'bg-blue-600 text-white'
         : 'bg-white text-blue-600'
   } absolute top-2/4 left-2/4 -translate-y-2/4 -translate-x-2/4  max-w-screen-md h-auto ${
      modalPosition ||
      'md:-translate-x-[calc(50%-64px)] lg:-translate-x-[calc(50%-96px)]'
   }`;
   const modalRef = useRef(null);

   useEffect(() => {
      const handleClick = (e) => {
         if (modalRef.current && !modalRef.current.contains(e.target)) {
            toggleModal && toggleModal();
         }
      };

      document.addEventListener('click', handleClick, true);

      return () => {
         document.removeEventListener('click', handleClick, true);
      };
   }, [toggleModal]);

   //see comment below Modal component for why the expression below
   if (!modalDisplay) {
      return null;
   }

   return (
      <div className={modalWrapperClass}>
         <div tabIndex={1} ref={modalRef} className={modalClass}>
            {children}
         </div>
      </div>
   );
}

/* 
   null is returned when display is hidden because of the condition
   <modalRef.current && !modalRef.current.contains(e.target) in the useEffect above>. 
   without returning null this condition will be true once the component mounts and the modal 
   will be toggled on when any part of the document is clicked.  That is modalRef.current will be 
   false as the reference won't be attached until the modal actually mounts in the DOM
*/
