import Button from '../forms/button';
import NumPadInput from './pin-input';
import NumPadBtns from './pin-buttons';
import { IoArrowBack } from 'react-icons/io5';
import ModalContainer from '../modals/modal-container';
import { useWindowResize } from 'utils/hooks/useWindowResize';
import {useNavigate} from "react-router-dom";

export default function NumPadModal({
   title,
   pin,
   setPin,
   amount,
   receiver,
   message,
   btnText,
   background='BLUE',
   btnOnClick,
   modalDisplay,
   toggleModal,
   togglePrevModal,
   modalPosition,
   authNumpad,
   outsideOnClick
}) {
   //TODO: figure out relationship with modal and verification compolete components
   //TODO: figure out responsive layout
   //TODO: work on more responsive classes, work on variants

   //styles
   const messageClass = `
      ${amount && 'order-2'} ${
      !authNumpad
         ? 'md:-order-4  md:border-b-2 md:border-secondary-lighter'
         : 'md:-oder-2'
   } 
   text-center text-xs whitespace-pre-wrap md:w-full md:text-lg md:p-2 md:text-secondary md:mb-10
   `;
   const titleClass = `
   ${amount && 'order-3'} ${!authNumpad ? 'md:oder-last mb-6' : 'md:-order-3'} 
   text-center font-semibold text-3xl whtespace-pre md:text-primary
   `;
   const transactionDetailsClass = 'flex justify-between mb-2 items-center order-1 md:-order-3'
   const detailClass = `${
      background === 'BLUE'
         ? 'bg-white text-primary'
         : 'bg-primary text-white'
   } ${receiver ? 'md:text-2xl' : 'md:text-5xl'} flex items-center rounded-3xl font-semibold p-2 mx-2 md:bg-inherit md:text-secondary-darker`;

   const navigate = useNavigate();

   const handleBack = () => {
      toggleModal();
    if (title.includes('student')){
      setPin("")
    } else if (title.includes('merchant')){
      navigate("../../../dashboard")
    }
      togglePrevModal();
   };

   const {width} = useWindowResize()

   return (
      <ModalContainer
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
         color={background}
         position={modalPosition}
         outsideOnClick={handleBack}
         variant='NUMPAD'
      >
         <IoArrowBack
            onClick={handleBack}
            className="fixed top-12 left-6 w-12 cursor-pointer text-4xl md:hidden md:w-8"
         />
         <div className="flex min-h-[75vh] w-full flex-col items-center md:block md:min-h-0">
            <div className="order-1 flex flex-col items-center">
               {amount && 
                  <div className={transactionDetailsClass}>
                     <p className={`${detailClass} text-2xl`}>{amount}</p>
                     {receiver && 
                        <> 
                           <img 
                              src='/icons/hollow_arrow.svg' 
                              alt="to"
                              className={`${background==='BLUE' ? 'filter-white' : 'filter-blue'} md:filter-slate`} 
                           />
                           <p className={`${detailClass} text-sm`}>{receiver}</p> 
                        </>}
                  </div>
               }
               <h1 className={titleClass}>{title}</h1>
               <p className={messageClass}>{message}</p>

               <NumPadInput
                  pin={pin}
                  setPin={setPin}
                  handleSubmit={btnOnClick}
                  background={background}
                  className={`${amount && 'order-4'} ${
                     !authNumpad
                        ? 'md:-oder-2 md:mb-[100px]'
                        : 'md:-order-4 md:mt-10'
                  }`}
               />
            </div>

            <NumPadBtns
               pin={pin}
               setPin={setPin}
               className={`order-2 md:hidden`}
            />
            <div className='flex flex-col items-center order-3 md: mb-10'>
               <Button color={background ==='BLUE' && width < 769  ? 'WHITE' : 'BLUE'}
                  onClick={btnOnClick}
               >
                  {btnText}
               </Button>
            </div>
         </div>
      </ModalContainer>
   );
}
