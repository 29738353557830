import { useState } from "react";
import Header from "components/shared/layout/headers/dashboard-header";
import RangeModal from "components/shared/modals/range-modal";
import { useFormData } from "utils/hooks/useFormData";
import TransactionsTable from "components/dashboard/transactions/table";
import useTransactions from "api/hooks/useTransactions";

export default function Transactions() {
   const initialState = {
      startDate: "",
      endDate: "",
      selectedFilter: "all",
   };
   //form states
   const [state, dispatch] = useFormData(initialState);
   const filterOptions = ["all", "payout", "transfer"];

   //modal states
   const [rangeModal, setRangeModal] = useState(false);

   //query call
   const {
      transactions: transactionsData,
      transactionsError,
      transactionsLoading,
      refetchTransactions,
   } = useTransactions();

   //handle functions
   const handleFilterSelect = (option) => {
      dispatch({
         type: "UPDATE_INPUT",
         payload: { selectedFilter: option },
      });
   };

   const handleDateChange = (name, value) => {
      dispatch({
         type: "UPDATE_INPUT",
         payload: { [name]: value },
      });
   };

   const handleFilter = () => {
      console.log("filtered");
   };

   return (
      <section className="mb-48 md:ml-32 lg:ml-48">
         <Header
            className="mb-2 pt-6 md:border-none md:bg-white md:text-slate-500"
            title="transactions"
            settingsUrl="../settings"
         />
         <TransactionsTable
            transactionsError={transactionsError}
            refetchTransactions={refetchTransactions}
            transactionsData={transactionsData}
            transactionsLoading={transactionsLoading}
            toggleModal={() => setRangeModal((prev) => !prev)}
         />
         <RangeModal
            toggleModal={() => setRangeModal((prev) => !prev)}
            modalDisplay={rangeModal}
            dispatch={dispatch}
            options={filterOptions}
            startDate={state.startDate}
            endDate={state.endDate}
            selectedFilter={state.selectedFilter}
            handleSubmit={handleFilter}
            handleDateChange={handleDateChange}
            handleRangeSelect={handleFilterSelect}
         />
      </section>
   );
}
