import Button from '../shared/forms/button';

export default function WelcomeSlideParentWrapper({
   url,
   title,
   text,
   secondaryText,
   isFinalSlide,
   screen,
   setScreen,
}) {
   return (
      <section className="mx-auto max-h-screen max-w-md px-6 py-7 md:max-w-sm">
         <div className="flex items-center justify-between">
            <img
               onClick={() => setScreen((prev) => prev - 1)}
               src="/icons/ic_baseline-arrow-back 2.svg"
               className="w-12 cursor-pointer md:w-8"
               alt=""
            />

            <span className="cursor-pointer text-xl text-secondary-darkest hover:text-stone-900 md:text-sm">
               Skip
            </span>
         </div>

         <img src={url} alt="" className="mt-16 mb-1 w-full md:mt-0" />

         <article className="text-center">
            {!isFinalSlide && (
               <h1 className="mb-1 text-xl font-bold text-primary">{title}</h1>
            )}

            <p className="text-secondary-darkest md:text-sm">{text}</p>

            <p className="mt-2 font-bold text-primary md:text-sm">
               {secondaryText}
            </p>
         </article>

         <ul className="mt-7 flex justify-center">
            <li
               className={`h-3 ${
                  screen === 1 ? 'w-8 bg-primary' : 'w-3 bg-primary-lighter-200'
               } mr-1 rounded-full`}
            ></li>

            <li
               className={`h-3 ${
                  screen === 2 ? 'w-8 bg-primary' : 'w-3 bg-primary-lighter-200'
               } mr-1 rounded-full`}
            ></li>

            <li
               className={`h-3 ${
                  screen === 3 ? 'w-8 bg-primary' : 'w-3 bg-primary-lighter-200'
               } mr-1 rounded-full`}
            ></li>

            <li
               className={`h-3 ${
                  screen === 4 ? 'w-8 bg-primary' : 'w-3 bg-primary-lighter-200'
               } mr-1 rounded-full`}
            ></li>
         </ul>

         <figure className="my-10 flex justify-center md:my-5">
            {isFinalSlide && (
               <Button size="WIDE" to="/setup">
                  Setup account
               </Button>
            )}

            {!isFinalSlide && (
               <img
                  onClick={() => setScreen((prev) => prev + 1)}
                  src="/icons/ic_baseline-arrow-back.svg"
                  className="w-14 cursor-pointer hover:animate-pulse md:w-10"
                  alt=""
               />
            )}
         </figure>
      </section>
   );
}
