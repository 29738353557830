import Button from '../forms/button';
import Input from '../forms/input';
import NumberFormat from 'react-number-format';
import ModalContainer from './modal-container';
import { AiOutlineCalendar } from 'react-icons/ai';
import FormGroup from '../forms/form-group';
import RadioBtn from '../forms/radio-btn';
import { useWindowResize } from 'utils/hooks/useWindowResize';

export default function RangeModal({
   startDate,
   endDate,
   selectedFilter,
   modalDisplay,
   toggleModal,
   title = '',
   options, 
   handleSubmit,
   handleRangeSelect,
   handleDateChange,
}) {

   const handleNumberFormat =(values, name) =>{
      const {formattedValue} = values
      handleDateChange(name, formattedValue)
   }

   const {width} = useWindowResize()

   return (
      <ModalContainer
         variant='AUTO'
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}     
      >
         <div className='container min-h-0 py-6 md:py-8 lg:py-12'>
            {options && 
               <RadioBtn 
                  options={options}
                  selected={selectedFilter}
                  handleSelect={handleRangeSelect}
               />}
            <h2 className="col-span-2 mb-6 font-semibold text-blue-600">
               {title}
            </h2>
            <FormGroup className='flex justify-between'>
               <FormGroup className='mx-2'>
                  <NumberFormat 
                     variant='LINE'
                     customInput={Input} 
                     value={startDate}
                     label="from"
                     format="##/##"
                     placeholder='dd/mm'
                     iconPosition='RIGHT_EDGE'
                     icon={<AiOutlineCalendar />}
                     mask={['d', 'd','m', 'm']}  
                     onValueChange={(values) =>handleNumberFormat(values, 'startDate')}
                  />
               </FormGroup>
               <FormGroup className='mx-2'>
                  <NumberFormat 
                     variant='LINE'
                     customInput={Input} 
                     format="##/##"
                     value={endDate}
                     label="to"
                     placeholder='dd/mm'
                     iconPosition='RIGHT_EDGE'
                     icon={<AiOutlineCalendar />}
                     mask={['d', 'd','m', 'm']}  
                     onValueChange={(values) =>handleNumberFormat(values, 'endDate')}
                  />
               </FormGroup>
            </FormGroup>
            <Button 
               size={width < 640 ? 'SMALL': 'NORMAL'}
               onClick={()=>{
                  handleSubmit()
                  toggleModal()
               }}
            > apply</Button>
         </div>
      </ModalContainer>
   );
}
