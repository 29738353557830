import Input from "components/dashboard/settings/input";
import { useEffect, useState } from "react";
import { MdOutlineSchool } from "react-icons/md";
import { MdOutlineLocationOn } from "react-icons/md";

export default function SettingsForm({ userData }) {
   const [merchantName, setMerchantName] = useState("");
   const [address, setAddress] = useState("");
   const formClass = "mx-auto w-5/6 max-w-screen-sm mb-6 sm:mb-12 md:mb-20";

   useEffect(() => {
      if (userData) {
         const  { storename, address } = userData;
         setMerchantName(storename);
         setAddress(address);
      }
   }, [userData, merchantName, address]);

   const handleSubmit = (e) => {
      e.preventDefault();
      console.log(e.target.value);
   };

   return (
      <form className={formClass} onSubmit={(e) => handleSubmit(e)}>
         <div className="flex sm:text-xl lg:text-[22px] lg:font-normal">
            <MdOutlineSchool className="self-end mb-[6px] mr-3 text-blue-600 text-3xl sm:mb-6 md:mb-8 md:text-4xl" />
            <Input
               label="merchant name"
               value={merchantName}
               onChange={(e) => setMerchantName(e.target.value)}
            />
         </div>
         <div className="flex sm:text-xl lg:text-[22px] lg:font-normal">
            <MdOutlineLocationOn className="self-end mb-[6px] mr-3 text-blue-600 text-3xl sm:mb-6 md:mb-8 md:text-4xl" />
            <Input
               label="address"
               value={address}
               onChange={(e) => setAddress(e.target.value)}
            />
         </div>
      </form>
   );
}
