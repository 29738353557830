import { classNames } from "utils/lib/getClassName";

const TITLE_MAPS = {
   PRIMARY: 'md:font-normal  md:text-secondary-darker',
   SECONDARY: 'md:font-normal  md:text-secondary-darker',
   TERTIARY: '',
}

const MESSAGE_MAPS = {
   PRIMARY: 'capitalize text-secondary-darker font-semibold',
   SECONDARY: 'text-primary',
   TERTIARY: 'capitalize text-secondary-darkest font-semibold',
}

export default function PageTitle({ 
   title, 
   subtitle, 
   message, 
   className, 
   variant='PRIMARY' 
}){
   
   //styles
   const titleClass = classNames(
      'capitalize text-primary text-2xl font-bold mb-1 md:text-3xl',
      TITLE_MAPS[variant]
   )
   const messageClass = classNames(
      'text-lg md:text-xl',
      MESSAGE_MAPS[variant]
   )

   return (
      <div className={`${className} self-stretch mb-10`}>
         <h1 className={titleClass}>
            {title}
         </h1>
         <h2 className={titleClass}>
            {subtitle}
         </h2>
         <p className={messageClass}>
            {message}
         </p>  
      </div>
   );
}


//both h1 and h2 share the same titleClass because both serve the same 
//purpose on the page, one is subtitle is passed when the Title is not the 
//the first on the page, example when its on a modal which means it is technically
//the second title on the page and not the first