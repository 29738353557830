import TableHeader from "components/shared/tables/table-header";
import TransactionsMonth from "./month";
import Spinner from "components/shared/feedbacks/spinner";
import FailModal from "components/shared/feedbacks/failure-modal";

export default function TransactionsTable({
   toggleModal,
   transactionsData,
   transactionsError,
   transactionsLoading,
   refetchTransactions,
}) {
   return (
      <div className="mx-auto w-5/6 sm:max-w-lg sm:text-lg  md:max-w-3xl md:text-xl">
         <TableHeader
            toggleModal={toggleModal}
            gridTemplateCols="grid-cols-custom-table-1"
            firstCol={"type"}
            otherCols={["date", "all"]}
            lastCol={"amount"}
         />
         {transactionsError && !transactionsLoading ? (
            <FailModal modalDisplay={true} btnOnClick={refetchTransactions} />
         ) : transactionsLoading ? (
            <div className="my-32 flex items-center justify-center">
               <Spinner color="GREY" size="MEDIUM" />
            </div>
         ) : (
            Object.keys(transactionsData).map((year) => {
               return (
                  <section className="mb-10" key={year}>
                     <h2 className="text-2xl font-semibold text-secondary-darker sm:pl-1 md:text-3xl">
                        {year}
                     </h2>
                     {transactionsData[year].map((month) => (
                        <TransactionsMonth
                           key={Object.keys(month)}
                           month={month}
                        />
                     ))}
                  </section>
               );
            })
         )}
      </div>
   );
}
