import { Link } from "react-router-dom";
import { BiUserCircle } from "react-icons/bi";
import useMerchant from "api/hooks/useMerchant";

export default function Header({ settingsUrl, className, title, children }) {
   //queries
   const { merchantId } = useMerchant();

   //styles
   const headerClass = `bg-blue-600 text-white w-full`;
   const titleClass = `self-end border-b-4 border-white-600 pl-7 text-2xl sm:text-3xl md:border-none md:pt-24 md:pl-12 lg:text-4xl lg:pl-20`;

   return (
      <header className={`${headerClass} ${className}`}>
         <div className="flex justify-between">
            <h1 className={title ? titleClass : ""}>{title}</h1>
            <div className="m-7 mb-2.5 flex items-center md:hidden">
               <p className="text-xs">
                  MERCHANT ID{" "}
                  <span className="block text-right text-sm">{merchantId}</span>
               </p>
               <Link to={settingsUrl || "../settings"}>
                  <BiUserCircle fontSize={35} />
               </Link>
            </div>
         </div>
         {children}
      </header>
   );
}
