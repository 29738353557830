import { axios } from "utils/lib/axios";

export const requestPayout = (queryObj) => {
   return axios.post("merchant/payouts/request", queryObj);
};

export const checkStudentName = async (student_id) => {
   return axios.get(`merchant/payments/student/verify/${student_id}`);
};

export const receivePayment = (queryObj) => {
   return axios.post("merchant/payments/student/receive", queryObj);
};
