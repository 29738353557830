import Input from './input';
import { useState } from 'react';
import FormGroup from './form-group';

export default function SearchInput({
   value,
   label,
   name,
   icon,
   iconPosition,
   dispatch,
   errorMessage,
   isValid,
   variant = 'PRIMARY',
   searchArr = [],
   handleSelect,
}) {
   const resultArr = searchArr.filter((i) => {
      return (
         value.length > 0 && i.toLowerCase().startsWith(value.toLowerCase())
      );
   });

   const [isActivated, setIsActivated] = useState('');
   const [dropdownShouldDisplay, setDropdownShouldDisplay] = useState('');

   const handleChange = (e) => {
      const { name, value } = e.target;

      setIsActivated(true);
      value.length > 0
         ? setDropdownShouldDisplay(true)
         : setDropdownShouldDisplay(false);

      dispatch({
         type: 'UPDATE_INPUT',
         payload: { [name]: value },
      });
   };

   const handleClick = handleSelect
      ? handleSelect
      : (value) => {
           dispatch({ type: 'UPDATE_INPUT', payload: { [name]: value } });
        };

   //styles
   const searchClass = `${dropdownShouldDisplay ? 'block' : 'hidden'}
   w-full -mt-5 bg-white absolute z-50 text-primary border border-secondary max-h-[250px] overflow-y-scroll`;

   const errorClass =
      'whitespace-pre-wrap -mt-5 pl-1 text-[10px] text-red-500 font-bolder';

   return (
      <FormGroup className="relative">
         <Input
            value={value}
            label={label}
            variant={variant}
            name={name}
            icon={icon}
            iconPosition={iconPosition}
            isValid={isValid}
            onChange={handleChange}
         />
         {isActivated && value.length < 1 && (
            <p className={errorClass}>{errorMessage.requiredError}</p>
         )}
         <div className={searchClass}>
            <ul>
               {value.length > 0 && resultArr.length < 0 ? (
                  <li className="p-3">
                     <p>No {label}s found</p>
                     <p className={errorClass}>{errorMessage.validityError}</p>
                  </li>
               ) : (
                  resultArr.map((element) => {
                     return (
                        <li
                           key={element}
                           className="py-2 pl-4"
                           onClick={() => {
                              handleClick(element);
                              setDropdownShouldDisplay(false);
                           }}>
                           {element}
                        </li>
                     );
                  })
               )}
            </ul>
         </div>
      </FormGroup>
   );
}
