import TableRow from 'components/shared/tables/table-row';
import TableCol from 'components/shared/tables/table-col';
import { BsFilter } from 'react-icons/bs';

export default function TableHeader({
   gridTemplateCols = 'grid-cols-4',
   toggleModal,
   firstCol,
   otherCols,
   lastCol,
}) {
   return (
      <div className="mx-auto mb-6 w-full max-w-screen-md text-sm font-medium text-slate-400 md:text-base">
         <TableRow gridTemplateCols={gridTemplateCols} rowTypeHeader={true}>
            <TableCol className="cursor-pointer">
               <BsFilter fontSize={25} onClick={toggleModal} />
               <p className="ml-2">{firstCol}</p>
            </TableCol>
            {otherCols.map((col, i) => (
               <TableCol key={i} className="justify-center">
                  {col}
               </TableCol>
            ))}
            <TableCol className="mr-3 justify-end">{lastCol}</TableCol>
         </TableRow>
      </div>
   );
}
