import { useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import ModalContainer from "components/shared/modals/modal-container";
import PageTitle from "components/shared/layout/headers/page-title";
import Spinner from "components/shared/feedbacks/spinner";

export default function ResetForm({
   modalDisplay,
   modalPosition,
   toggleModal,
   state,
   dispatch,
   loading,
   handleSubmit,
   formValidation,
}) {
   const [showPassword, setShowPassword] = useState(false);
   const [showPasswordConfirmation, setShowPasswordConfirmation] =
      useState(false);

   const handleChange = (e) => {
      const { name, value } = e.target;
      dispatch({
         type: "updateInput",
         payload: { name, value },
      });
   };

   return (
      <ModalContainer
         modalDisplay={modalDisplay}
         modalPosition={modalPosition}
         toggleModal={toggleModal}
      >
         <div className="container pt-24 md:py-12">
            <PageTitle
               variant="TERTIARY"
               title="reset password"
               message="please reset your ledga password"
            />
            <form className="self-stretch mb-10" autoComplete="new-password">
               <input type="hidden" value="something" />
               <Input
                  value={state.password}
                  name="password"
                  label="password"
                  iconPosition="RIGHT"
                  showPassword={showPassword}
                  autoComplete="new-password"
                  type={showPassword ? "text" : "password"}
                  onChange={handleChange}
                  iconOnClick={() => setShowPassword((prev) => !prev)}
                  icon={
                     showPassword ? (
                        <IoEyeOffOutline className="text-2xl" />
                     ) : (
                        <IoEyeOutline className="text-2xl" />
                     )
                  }
                  errorMessage={{
                     requiredError: `password must be at least 8 digits long,\ncontain at least 1 uppercase & lowercase character`,
                     validityError: `password must be at least 8 digits long,\ncontain at least 1 uppercase & lowercase character`,
                  }}
                  isValid={formValidation.password}
               />
               <Input
                  value={state.passwordConfirmation}
                  name="passwordConfirmation"
                  label="confirm password"
                  iconPosition="RIGHT"
                  autoComplete="new-password"
                  onChange={handleChange}
                  showPassword={showPasswordConfirmation}
                  iconOnClick={() =>
                     setShowPasswordConfirmation((prev) => !prev)
                  }
                  type={showPasswordConfirmation ? "text" : "password"}
                  icon={
                     showPasswordConfirmation ? (
                        <IoEyeOffOutline className="text-2xl" />
                     ) : (
                        <IoEyeOutline className="text-2xl" />
                     )
                  }
                  errorMessage={{
                     requiredError: "please enter a matching password",
                     validityError: "please enter a matching password",
                  }}
                  isValid={formValidation.passwordConfirmation}
               />
            </form>
            <Button disabled={loading} size="WIDE" onClick={handleSubmit}>
               {loading ? <Spinner color="WHITE" /> : "next"}
            </Button>
         </div>
      </ModalContainer>
   );
}
