import { BsDownload } from "react-icons/bs";
import ReceiptField from "./receipt-field";
import ArrowBack from "../layout/nav/arrow-back";
import ModalContainer from "components/shared/modals/modal-container";
import getReceipt from "./receipts";

export default function TransactionReceipt({
   transaction,
   modalDisplay,
   toggleModal,
}) {
   return (
      <ModalContainer
         outsideOnClick={toggleModal}
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
      >
         <ArrowBack handleBack={toggleModal} color="WHITE" />
         <div id={transaction._id}>
            <div className="flex items-center justify-between bg-blue-600 px-12 pt-16 pb-3 capitalize text-white sm:px-20 md:px-12 md:pt-7">
               <h2 className="">transaction details</h2>

               <div
                  onClick={() => getReceipt(transaction)}
                  className="flex cursor-pointer flex-col items-center"
               >
                  <BsDownload fontSize={20} />
                  <p className="text-xs">download</p>
               </div>
            </div>

            <div className="mx-auto max-w-[500px] px-12 md:max-h-[350px] md:overflow-y-scroll">
               <ReceiptField label="Date" value={transaction.formattedDate} />

               <ReceiptField label="time" value={transaction.formattedTime} />

               <ReceiptField
                  label="amount"
                  value={transaction.formattedAmount}
               />

               <ReceiptField
                  label="full amount"
                  value={transaction.originalAmount}
               />

               <ReceiptField
                  label="transaction type"
                  value={transaction.transaction_type}
               />

               <ReceiptField
                  label="reference"
                  value={transaction.transaction_refernce}
               />

               <div className="my-12">
                  <h2 className="text-sm">Sender</h2>
                  <ReceiptField label="Id" value={transaction.sender?.id} />
                  <ReceiptField
                     label="Acount Number"
                     value={transaction.sender?.accountNumber}
                  />
                  <ReceiptField
                     label="Bank Name"
                     value={transaction.sender?.bankName}
                  />
               </div>

               <div>
                  <h2 className="text-sm">Recipient</h2>
                  <ReceiptField label="Id" value={transaction.recipient?.id} />
                  <ReceiptField
                     label="Acount Number"
                     value={transaction.recipient?.accountNumber}
                  />
                  <ReceiptField
                     label="Bank Name"
                     value={transaction.recipient?.bankName}
                  />
               </div>
            </div>
         </div>
      </ModalContainer>
   );
}
