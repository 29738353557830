export default function getSorted(transactionData) {
   const sortedArr = transactionData.sort((a, b) => {
      const aDate = new Date(a._id.year, a._id.month - 1);
      const bDate = new Date(b._id.year, b._id.month - 1);
      return bDate.getTime() - aDate.getTime();
   });

   const yearsObject = sortedArr.reduce((r, i) => {
      const year = i._id.year;
      const month = i._id.month;
      const data = { [month]: i.docs };

      if (r.hasOwnProperty(year)) {
         return { ...r, [year]: [...r[year], data] };
      } else {
         return { ...r, [year]: [data] };
      }
   }, {});

   return yearsObject;
}
