import { BsBank } from 'react-icons/bs';
import { BiUserCircle } from 'react-icons/bi';
import { IoMdLogOut } from "react-icons/io";
import { AiOutlineLock } from 'react-icons/ai';
import { IoChatboxOutline } from 'react-icons/io5';
import SettingsForm from 'components/dashboard/settings/form';
import LinkItem from 'components/dashboard/settings/link-item';
import useMerchant from 'api/hooks/useMerchant';
import { signOut } from "api/services/auth.service";

export default function Settings() {

     //queries
     const { merchant } = useMerchant();

   //styles
   const iconClass = 'mr-3 text-xl md:text-3xl'

   return (
      <section className="mb-48 md:ml-32 lg:ml-48">
         <BiUserCircle className="my-20 mx-auto text-9xl text-gray-300 md:text-[180px]" />
         <SettingsForm userData={merchant}  />
         <div className="mx-auto w-5/6 max-w-screen-sm">
            <LinkItem
               label="set bank details"
               icon={<BsBank className={iconClass} />}
               to="bank-details"
            />
            <LinkItem
               to="change-pin"
               label="change transaction pin"
               icon={<AiOutlineLock className={iconClass} />}
            />
            <LinkItem
               to="reset-password"
               label="reset password"
               icon={<AiOutlineLock className={iconClass} />}
            />
            <LinkItem
               to="contact-us"
               label="contact support"
               icon={<IoChatboxOutline className={iconClass} />}
            />
             <LinkItem
               btnOnclick={signOut}
               label="Logout"
               icon={<IoMdLogOut className={iconClass} />}
            />
         </div>
      </section>
   );
}
