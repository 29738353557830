import useWithdrawal from "api/hooks/useWithdrawal";

import AmountModal from "./amout-modal";
import NumPadModal from "components/shared/numpad/numpad-modal";
import SuccessFeedbackModal from "components/shared/feedbacks/success-modal";
import LoadingModal from "components/shared/feedbacks/loading-modal";
import FailModal from "components/shared/feedbacks/failure-modal";
import BankDetailsDisplay from "./bank-details-display";

export default function WithdrawModals({
   modalDisplay,
   setModalDisplay,
   id = "",
   withdrawService,
   mutateFn,
   balance,
   bankDetails,
}) {
   const {
      pin,
      setPin,
      state,
      dispatch,
      errorBtnText,
      errorMessage,
      withdrawLoading,
      successModal,
      numpadModal,
      errorModal,
      amountModal,
      toggleAmountModal,
      toggleErrorModal,
      toggleNumpadModal,
      toggleSuccessModal,
      handleNext,
      handleSubmit,
      handleClick,
   } = useWithdrawal(withdrawService, id, mutateFn, setModalDisplay);

   return (
      <>
         <BankDetailsDisplay
            modalDisplay={modalDisplay}
            setModalDisplay={setModalDisplay}
            bankDetails={bankDetails}
            handleNext={handleNext}
         />
         <AmountModal
            state={state}
            dispatch={dispatch}
            modalDisplay={amountModal}
            handleClick={handleClick}
            toggleModal={toggleAmountModal}
            balance={balance}
         />
         <NumPadModal
            pin={pin}
            setPin={setPin}
            title="transaction pin"
            background="BLUE"
            btnText="proceed"
            btnOnClick={handleSubmit}
            amount={state.formattedAmount}
            message="confirm withdrawal"
            modalDisplay={numpadModal}
            toggleModal={toggleNumpadModal}
         />
         <LoadingModal
            modalDisplay={withdrawLoading}
            message="Please wait while your payout request is being sent"
         />
         <FailModal
            modalDisplay={errorModal}
            message={errorMessage}
            btnText={errorBtnText}
            toggleModal={toggleErrorModal}
            btnOnClick={toggleErrorModal}
         />
         <SuccessFeedbackModal
            modalDisplay={successModal}
            src="/icons/arcticons_ok-payment.svg"
            title="Withdrawal Successful"
            message="You will receive  your payout in your account once it has been processed"
            btnText="next"
            toggleModal={toggleSuccessModal}
            btnOnClick={toggleSuccessModal}
         />
      </>
   );
}
