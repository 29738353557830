import WelcomeSlideParentWrapper from './slide-parent-wrapper';

export default function WelcomeSlideThree({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/shopping _ confirm, approve, complete, shop, cart, purchase, invoice, receipt, woman 1Student.svg"
         title="Control Your Finance"
         text="Save money with Ledga without having to worry about bank charges. Control your payouts."
         screen={3}
         setScreen={setScreen}
      />
   );
}
