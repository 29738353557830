import { useState } from 'react';
import NumPadModal from 'components/shared/numpad/numpad-modal';
import LoadingModal from 'components/shared/feedbacks/loading-modal';
// import TransferSuccess from 'components/dashboard/transfer/transfer-succes';
import TransactionReceipt from "components/shared/receipt/transaction-receipt";
import useRequest from "utils/hooks/useRequest";
import {receivePayment} from "api/services/merchants.service";
import FailModal from "components/shared/feedbacks/failure-modal";
import {useNavigate} from "react-router-dom";
import { getIsoCurrencyFormat } from "utils/lib/number-formatter";
import { getFormattedDate, getFormattedTime } from "utils/lib/number-formatter";


export default function ReceiveNumPad({
   createPinModal,
   toggleCreatePinModal,
   modalPosition,
   amount,
   btnText,
   btnOnClick,
   outsideOnClick,
   state,
   formIsValid
}) {
   //TODO: handleSubmit and set screen on resolve
   //TODO: error message will be needed too

   const navigate = useNavigate();



   const [pin, setPin] = useState('');
   const [merchantPin, setMerchantPin] = useState('');

   const [confirmPinModal, setConfirmPinModal] = useState(false);
   const [loadingModal, setLoadingModal] = useState(false);
   const [successModal, setSuccessModal] = useState(false);
   const [failureModal, setFailureModal] = useState(false);
   const [transactionDetails, setTransactionDetails] = useState([ { merchantId: "DEF0909" },
   { transactionId: "0909" },
   { amount: "8000" },
   { userId: "USE789" },
   { date: "09/09/2008" },
   { time: '13:06:36' },]);

   const toggleLoadingModal = (value) =>  setLoadingModal(value);
   const toggleSuccessModal = () =>  setSuccessModal(!successModal);
   const toggleConfirmPinModal = (value) => setConfirmPinModal(value);



   // const pinIsValid= /\d{4}/.test(pin)

   const handleNext = () => {
      toggleCreatePinModal(false);
      toggleConfirmPinModal(true);
   };


   //3. get setup function from useRequest hook    
   const { 
      queryFn: receiveFn, 
      loading: receiveLoading,
      error: receiveError
   } = useRequest(receivePayment);



   //3c. get data if successful and pass
   //to stateful variables which are in turn used as props
   const onReceiveSuccess = (data) => {
      toggleLoadingModal(false);
      toggleSuccessModal();
      console.log(data)

      const { amount, created_at, from, to, _id, transaction_type} = data;
   

      setTransactionDetails({
         formattedDate: getFormattedDate(created_at),
         formattedTime: getFormattedTime(created_at),
         formattedAmount: getIsoCurrencyFormat(amount),
         amount: amount,
         transaction_type: transaction_type,
         transaction_reference: _id,
         sender: { id: from },
         recipient: { id: to },
      });
   };

 

   const onReceiveError = (message) => {
         console.log(receiveError)
         setLoadingModal(false);
         setFailureModal(true);
   };

   const handleError = () => {
      navigate("../../../dashboard");
   };


     //3b. extract data from state and submit
     const handleSubmit = () => {
      const {amount, id, paymentPurpose  } = state
      const queryObj = {
      student_unique_id: id,
      amount: amount,
      student_pin : pin, 
      merchant_pin: merchantPin,
      purpose :paymentPurpose,
      };

      console.log(queryObj);
      console.log(formIsValid)

      toggleConfirmPinModal(false);
      toggleLoadingModal(true);

      //there should be an formIsValid
      // if (formIsValid) {
         receiveFn(queryObj, {
            onSuccess: onReceiveSuccess,
            onError: onReceiveError,
         });
      // }
      setPin('');
      setMerchantPin('');
  
   };

   
   

   const handleFinish = () =>{
      toggleSuccessModal(false)
      btnOnClick()
   }

   return (
      <>
         <NumPadModal
            authNumpad
            modalPosition={modalPosition}
            amount={amount}
            pin={pin}
            setPin={setPin}
            title={`student pin`}
            message={`Input your student pin`}
            btnText="proceed"
            btnOnClick={handleNext}
            outsideOnClick={outsideOnClick}
            modalDisplay={createPinModal}
            toggleModal={toggleCreatePinModal}
         />
         <NumPadModal
            authNumpad
            modalPosition={modalPosition}
            amount={amount}
            pin={merchantPin}
            setPin={setMerchantPin}
            title="merchant pin"
            message="input your merchant pin"
            btnText="proceed"
            btnOnClick={handleSubmit}
            outsideOnClick={outsideOnClick}
            modalDisplay={confirmPinModal}
            toggleModal={toggleConfirmPinModal}
         />
         <LoadingModal
            modalPosition={modalPosition}
            modalDisplay={loadingModal}
            toggleModal={toggleLoadingModal}
            message="please wait while your transaction is processed"
         />

        <TransactionReceipt
            modalDisplay={successModal}
            toggleModal={toggleSuccessModal}
            transaction={transactionDetails}
         />
            <FailModal
            title="Failed Transaction"
            message={receiveError}
            modalDisplay={failureModal}
            btnTitle="try again"
            onClickBtn={handleError}
         />
         

      </>
   );
}
