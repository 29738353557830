export default function SummaryButton({ name, label, isActive, setIsActive }) {
   const selectClass =
      "hover:underline hover:text-blue-600 hover:cursor-pointer focus:underline focus:text-blue-600";
   return (
      <p
         tabIndex={1}
         className={`${
            isActive === name && "text-blue-600 underline"
         } ${selectClass} mr-1 sm:mr-2 md:mr-3 lg:mr-4`}
         onClick={() => setIsActive(name)}
      >
         {label}
      </p>
   );
}
