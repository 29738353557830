import useSWR from "swr";
import { useState, useEffect } from "react";
import { getAllRequestsUrl } from "api/services/payments.service";
import getSortedData from "utils/lib/getSortedData";

export default function useAllRequests(config) {
   const [transformedRequests, setTransformedRequests] = useState([]);

   const {
      data: requests,
      error: requestsError,
      mutate: mutateRequests,
      isValidating: requestsMutating,
   } = useSWR(getAllRequestsUrl, config);

   const requestsLoading = (!requests && !requestsError) || requestsMutating;

   useEffect(() => {
      if (requests) {
         setTransformedRequests(getSortedData(requests));
      }
   }, [requests]);

   return {
      requests: transformedRequests,
      requestsError,
      requestsLoading,
      mutateRequests,
      requestsMutating,
   };
}
