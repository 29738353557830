import WelcomeSlideParentWrapper from './slide-parent-wrapper';

export default function WelcomeSlideTwo({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/communication, transportation _ fly, airplane, paper, memo, man, people, transport, delivery 1Student.svg"
         title="Track Sales"
         text="Track sales, measure and scale your business growth as you collect payments"
         screen={2}
         setScreen={setScreen}
      />
   );
}
