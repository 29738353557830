import useSWR from "swr";
import { useState, useEffect } from "react";
import { getTransactionsUrl } from "api/services/payments.service";
import useMerchant from "./useMerchant";
import { getTransformedTransaction } from "utils/lib/getTransformedTransactions";
import getSortedData from "utils/lib/getSortedData";

const getCombinedMonthsData = (data) => {
   return data
      .reduce((result, month) => {
         return [...result, ...month.docs];
      }, [])
      .sort((a, b) => {
         const aDate = new Date(a.created);
         const bDate = new Date(b.created);
         return bDate.getTime() - aDate.getTime();
      });
};

export default function useTransactions() {
   const [payouts, setPayouts] = useState([]);
   const [transfers, setTransfers] = useState([]);
   const [transformedTransactions, setTransformedTransactions] = useState([]);

   const {
      data: transactions,
      error: transactionsError,
      mutate: mutateTransactions,
      isValidating: transactionsMutating,
   } = useSWR(getTransactionsUrl);

   const { error: payoutsError } = useSWR(
      `${getTransactionsUrl}?transaction_type=payout`,
      {
         onSuccess: (data) => {
            setPayouts(getCombinedMonthsData(data));
         },
      },
   );

   const { error: transfersError } = useSWR(
      `${getTransactionsUrl}?transaction_type=transfer`,
      {
         onSuccess: (data) => setTransfers(getCombinedMonthsData(data)),
      },
   );

   const { merchantId, mutate: mutateMerchant } = useMerchant();

   //refetch transactions, mutate merchant alongside transactions
   //so the useEffect can run
   const refetchTransactions = () => {
      mutateTransactions();
      mutateMerchant();
   };

   const payoutsLoading = !payouts && !payoutsError;
   const transfersLoading = !transfers && !transfersError;
   const transactionsLoading =
      (!transactions && !transactionsError) || transactionsMutating;

   useEffect(() => {
      if (transactions && merchantId) {
         const newTransactions = getTransformedTransaction(
            transactions,
            merchantId,
         );
         setTransformedTransactions(getSortedData(newTransactions));
      }
   }, [transactions, merchantId]);

   return {
      payouts,
      transfers,
      payoutsError,
      transfersError,
      payoutsLoading,
      transfersLoading,
      transactionsError,
      transactionsLoading,
      mutateTransactions,
      refetchTransactions,
      transactions: transformedTransactions,
   };
}
