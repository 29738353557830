import { useState } from "react";
import { useNavigate } from "react-router-dom";
import NumberFormat from "react-number-format";
import Header from "components/shared/layout/headers/dashboard-header";
import CardsGroup from "components/dashboard/home/cards-group";
import TransactionSummary from "components/dashboard/home/summary";
import PaymentHistory from "components/dashboard/home/payment-history";
import CreatePinModal from "components/setup/create-pin-modal";
import useMerchant from "api/hooks/useMerchant";
import useTransactions from "api/hooks/useTransactions";
import getTransactionsSummary from "utils/lib/getTransactionsSummary";

export default function DashboardHome() {
   //modal states
   const [createPinModal, setCreatePinModal] = useState("");

   const navigate = useNavigate();

   //queries
   const { merchant } = useMerchant({
      onSuccess: (data) => {
         if (!data.unique_id) {
            navigate("../setup", { replace: true });
         } else if (!data.meta?.transaction_pin) {
            setCreatePinModal(true);
         }
      },
   });

   const { payouts, transfers } = useTransactions();

   const summaryData = getTransactionsSummary(payouts, transfers);

   return (
      <section className="mb-48 md:ml-32 lg:ml-48">
         <Header className="rounded-custom-b-lr-6xl py-8 md:rounded-none md:bg-white md:py-0 md:pt-28 md:text-slate-500">
            <div className="mx-auto table border-slate-400 pb-4 md:border-b-2">
               <p>BAL</p>
               <p className="rounded-2xl bg-blueSpectrum-base2 p-4 text-5xl font-semibold md:bg-white md:p-0 md:text-6xl lg:text-7xl">
                  <NumberFormat
                     value={merchant?.meta.balance}
                     displayType={"text"}
                     thousandSeparator={true}
                     prefix={"₦"}
                  />
               </p>
            </div>
         </Header>
         <div className="mx-auto flex max-w-4xl flex-col">
            <CardsGroup />
            <TransactionSummary summaryData={summaryData} />
            <div className="lg:flex">
               <PaymentHistory
                  transactionType="payout"
                  transactionData={payouts}
                  className="lg:border-r-2 lg:border-secondary-lighter"
               />
               <PaymentHistory
                  paymentType="transfer"
                  transactionData={transfers}
               />
            </div>
         </div>
         <CreatePinModal
            modalDisplay={createPinModal}
            setCreatePinModal={setCreatePinModal}
         />
      </section>
   );
}

// used dispaly:table to set width to content-width. mx-auto to center
