import { BrowserRouter } from "react-router-dom";
import { ApiRoutes } from "api/routes/router";
import { SWRConfig } from "swr";
import { fetcher } from "utils/lib/fetcher";

function App() {
   return (
      <SWRConfig value={{ fetcher: fetcher }}>
         <BrowserRouter>
            <ApiRoutes />
         </BrowserRouter>
      </SWRConfig>
   );
}

export default App;
