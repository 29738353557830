import { useEffect, useRef } from 'react';
import { useWindowResize } from 'utils/hooks/useWindowResize';

export default function NumPadInput({
   className,
   pin,
   setPin,
   handleSubmit,
   background,
}) {
   // TODO: add error messages

   const inputRef = useRef();
   const { width } = useWindowResize();
   // const [error, setError] = useState(false)
   // const pinIsValid= /\d{4}/.test(pin)

   useEffect(() => {
      if (inputRef) {
         inputRef.current.focus();
      }
   });

   const handleChange = (e) => {
      const { value } = e.target;
      if (!/^\d{0,4}$/.test(value)) {
         // setError(true)
      } else if (e.target.value.length <= 4) {
         // setError(false)
         setPin(value);
      }
   };

   const handleBlur = () => {
      setTimeout(() => {
         inputRef.current.focus();
      }, 100);
   };

   const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
         handleSubmit();
      }
   };

   //styles
   const fieldStyle = `border-2 ${
      background === 'BLUE'
         ? 'border-white bg-white'
         : 'border-primary bg-primary'
   } w-5 h-5 rounded-2xl md:border-primary md:rounded-none md:bg-white md:w-12 md:h-12 md:flex md:justify-center md:items-center md:text-3xl`;

   return (
      <div className={`${className} relative mb-10 md:mb-16`}>
         <input
            ref={inputRef}
            value={pin}
            name="pin"
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            type="text"
            readOnly={width > 640 ? false : true}
            className="opacity-0"
            onBlur={handleBlur}
            autoComplete="off"
         />
         <div className="absolute top-0 left-2/4 mt-4 flex w-[250px] -translate-x-2/4 justify-between text-primary">
            <div className={`${fieldStyle} ${pin.length < 1 && 'bg-inherit'}`}>
               <span className="hidden md:block">{pin[0]}</span>
            </div>
            <div className={`${fieldStyle} ${pin.length < 2 && 'bg-inherit'}`}>
               <span className="hidden md:block">{pin[1]}</span>
            </div>
            <div className={`${fieldStyle} ${pin.length < 3 && 'bg-inherit'}`}>
               <span className="hidden md:block">{pin[2]}</span>
            </div>
            <div className={`${fieldStyle} ${pin.length < 4 && 'bg-inherit'}`}>
               <span className="hidden md:block">{pin[3]}</span>
            </div>
         </div>
         {/* <p className="absolute top-20 border-2 border-black w-full">{error && "please enter only numeric inputs 0-9"}</p>
         <p className="absolute bottom-2">{ pinIsValid && "press ENTER to continue"}</p> */}
      </div>
   );
}
