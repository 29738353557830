import NumberFormat from "react-number-format";
import Input from "components/shared/forms/input";
import { IoArrowBack } from "react-icons/io5";
import Button from "components/shared/forms/button";
import PageTitle from "components/shared/layout/headers/page-title";
import ModalContainer from "components/shared/modals/modal-container";
import { useNavigate } from "react-router-dom";
import useFormValidation from "utils/hooks/useFormValidation";
import { getOnchangeHandler } from "utils/lib/getOnchangeHandler";
import FormGroup from "components/shared/forms/form-group";

export default function RequestPayoutForm({
   state,
   dispatch,
   modalDisplay,
   toggleModal,
   handleNext,
   accountDetails,
   balance,
}) {
   const navigate = useNavigate();
   const { formValidation } = useFormValidation(state);

   const handleNumberFormat = getOnchangeHandler(dispatch, "number-format");

   return (
      <ModalContainer
         area="MEDIUM"
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
      >
         <IoArrowBack
            onClick={() => navigate("../../../dashboard")}
            className="fixed top-10 left-4 w-12 cursor-pointer text-4xl md:hidden md:w-8"
         />
         <div className="full-page container pt-24 pb-10 md:py-12">
            <PageTitle
               variant="TERTIARY"
               title="request payout"
               message="enter transaction details"
            />
            <p className="mb-3 self-start text-left text-lg text-yellow-600">
               bal: {balance}
            </p>
            <form className="self-stretch">
               <FormGroup className="mb-6">
                  <NumberFormat
                     customInput={Input}
                     value={state.amount}
                     prefix={"₦"}
                     thousandSeparator={true}
                     label="amount"
                     placeholder="₦"
                     errorMessage={{
                        requiredError: "amount cannot be empty",
                        validityError: "please enter a valid amount",
                     }}
                     isValid={formValidation.amount}
                     onValueChange={(values) =>
                        handleNumberFormat(values, "amount", "formattedAmount")
                     }
                  />
                  <p className="-mt-4 pl-1 text-xs">
                     note that a processing fee of ₦5 is charged by the bank
                  </p>
               </FormGroup>

               <Input
                  variant="LINE"
                  name="account"
                  value={accountDetails.toLowerCase()}
                  label="account"
                  disabled
               />
            </form>

            <div className="flex flex-col items-center">
               <div className="mb-12 md:mb-8">
                  <Button
                     to="../../dashboard/settings/bank-details"
                     variant="TERTIARY"
                     color="YELLOW_TEXT"
                  >
                     change payout account &gt;
                  </Button>
               </div>
               <Button size="WIDE" onClick={handleNext}>
                  request
               </Button>
            </div>
         </div>
      </ModalContainer>
   );
}
