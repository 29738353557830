import { Fragment } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from 'components/shared/layout/nav/sidebar';

export default function DashboardNav() {
   return (
      <Fragment>
         <Sidebar />
         <Outlet />
      </Fragment>
   );
}
