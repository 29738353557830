import useSWR from "swr";
import { useState, useEffect } from "react";
import { getBanksUrl } from "api/services/payments.service";
import { getNubanName } from "api/services/payments.service";
import useRequest from "utils/hooks/useRequest";
import useFormValidation from "utils/hooks/useFormValidation";
import useInputUpdate from "utils/hooks/useInputUpdate";

export default function useBankDetails(state, dispatch, handleError) {
   const [bankNames, setBankNames] = useState([]);
   const [bankData, setBankData] = useState("");

   const { formValidation } = useFormValidation(state);

   //fetch data,
   const { error: bankDataError, mutate: mutateBankData } = useSWR(
      getBanksUrl,
      {
         onSuccess: (data) => {
            setBankData(data.banks);
            const banks = data.banks.map((bank) => bank.bankName);
            setBankNames(banks);
         },
         onError: (err) => handleError(err.message),
      },
   );

   const bankDataLoading = !bankData && !bankDataError;

   //query functions
   const { queryFn: getNubanFn, loading: getNubanLoading } =
      useRequest(getNubanName);

   //handle functions
   const handleBankSelect = (value) => {
      const selectedBank = bankData.filter((bank) => bank.bankName === value);
      const bankCode = selectedBank[0].bankCode;
      dispatch({
         type: "UPDATE_INPUT",
         payload: { bankName: value, bankCode: bankCode },
      });
   };

   //get nuban name once bankName and bankCode are filled
   useEffect(() => {
      if (state.bankCode > 1 && formValidation.accountNumber) {
         const queryObj = {
            account_number: state.accountNumber,
            bank_code: state.bankCode,
         };
         getNubanFn(queryObj, {
            onSuccess: (data) => {
               if (data.BeneficiaryName.length < 1) {
                  handleError(
                     "Please check bank details and try again",
                     "Beneficiary not found",
                  );
               } else {
                  dispatch({
                     type: "UPDATE_INPUT",
                     payload: { accountName: data.BeneficiaryName },
                  });
               }
            },
            onError: (message) => handleError(message),
         });
      }
   }, [
      getNubanFn,
      handleError,
      state.accountNumber,
      state.bankCode,
      dispatch,
      formValidation.accountNumber,
   ]);

   const handleChange = useInputUpdate(dispatch);

   return {
      bankNames,
      bankData,
      bankDataError,
      bankDataLoading,
      mutateBankData,
      getNubanLoading,
      handleChange,
      handleBankSelect,
   };
}
