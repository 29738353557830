import { getFormattedPercentage, getFormattedAmount } from "./number-formatter";

export default function getTransactionsSummary(payouts, transfers) {
   const currentDate = new Date();

   const getCurrentDay = (dateObj) => {
      if (dateObj.getDay() === 0) {
         return 6;
      } else {
         return dateObj.getDay() - 1;
      }
   };

   const startOfWeek = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate() - getCurrentDay(currentDate),
   );

   const startOfMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth(),
   );

   const transactionDate = (createdDate) => new Date(createdDate).getTime();

   const getCalculatedPeriod = (transactions, filter) => {
      const amount = transactions
         .filter((e) => transactionDate(e.created) >= filter)
         .reduce((r, e) => {
            return r + e.amount;
         }, 0);
      const formattedAmount = getFormattedAmount(amount);

      return { amount, formattedAmount };
   };

   //monthly transactions
   const monthPayouts = getCalculatedPeriod(payouts, startOfMonth.getTime());
   const monthTransfers = getCalculatedPeriod(
      transfers,
      startOfMonth.getTime(),
   );
   const monthTotal = monthPayouts.amount + monthTransfers.amount;
   const monthTransfersPct = getFormattedPercentage(
      monthTransfers.amount / monthTotal,
   );
   const monthPayoutsPct = getFormattedPercentage(
      monthPayouts.amount / monthTotal,
   );

   //weekly transactions
   const weekPayouts = getCalculatedPeriod(payouts, startOfWeek.getTime());
   const weekTransfers = getCalculatedPeriod(transfers, startOfWeek.getTime());
   const weekTotal = weekPayouts.amount + weekTransfers.amount;
   const weekTransfersPct = getFormattedPercentage(
      weekTransfers.amount / weekTotal,
   );
   const weekPayoutsPct = getFormattedPercentage(
      weekPayouts.amount / weekTotal,
   );

   //daily transactions.
   const dayPayouts = getCalculatedPeriod(payouts, currentDate.getTime());
   const dayTransfers = getCalculatedPeriod(transfers, currentDate.getTime());
   const dayTotal = dayPayouts.amount + dayTransfers.amount;
   const dayTransfersPct = getFormattedPercentage(
      dayTransfers.amount / dayTotal,
   );
   const dayPayoutsPct = getFormattedPercentage(dayPayouts.amount / dayTotal);

   return {
      month: {
         payouts: monthPayouts.formattedAmount,
         transfers: monthTransfers.formattedAmount,
         total: getFormattedAmount(monthTotal),
         transfersPct: monthTransfersPct,
         payoutsPct: monthPayoutsPct,
      },
      week: {
         payouts: weekPayouts.formattedAmount,
         transfers: weekTransfers.formattedAmount,
         total: getFormattedAmount(weekTotal),
         transfersPct: weekTransfersPct,
         payoutsPct: weekPayoutsPct,
      },
      day: {
         payouts: dayPayouts.formattedAmount,
         transfers: dayTransfers.formattedAmount,
         total: getFormattedAmount(dayTotal),
         transfersPct: dayTransfersPct,
         payoutsPct: dayPayoutsPct,
      },
   };
}
