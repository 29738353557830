import NavItem from './nav-item';
import { MdHomeFilled, MdPayments, MdSettings } from 'react-icons/md';
import { IoMdLogOut } from 'react-icons/io';
import { BiUserCircle, BiTransfer } from 'react-icons/bi';
import { signOut } from "api/services/auth.service";
import useMerchant from 'api/hooks/useMerchant';

export default function Sidebar() {
   const navItemClassName = "text-2xl md:text-xl mx-auto";
   const { merchantId } = useMerchant();


   return (
      <aside className="fixed z-10 shadow-xl border flex justify-between md:block w-full md:w-32 lg:w-48 md:top-0 bottom-0 md:h-screen md:border-r md:p-6 bg-white transition dark:bg-blue-600 dark:border-none">
         {/* user icon top */}
         <div className="hidden md:block text-center text-sm text-neutral-500 mb-10 mt-5 dark:text-neutral-200">
            <BiUserCircle fontSize={30} className="mx-auto" />
            <span>ID: {merchantId}</span>
         </div>

         {/* main dashboard nav links */}
         <NavItem
            label="Home"
            href="/dashboard"
            icon={<MdHomeFilled className={navItemClassName} />}
         />

         <NavItem
            label="Transactions"
            href="/dashboard/transactions"
            icon={<BiTransfer className={navItemClassName} />}
         />

         <NavItem
            label="Requests"
            href="/dashboard/requests"
            icon={<MdPayments className={navItemClassName} />}
         />

         <NavItem
            label="Settings"
            href="/dashboard/settings"
            icon={<MdSettings className={navItemClassName} />}
         />

         {/* logout button bottom */}
         <div className="bottom-0 absolute w-full left-0 right-0 hidden md:flex md:justify-center">
            <NavItem
               btnOnClick={signOut}
               label="Logout"
               icon={<IoMdLogOut className={navItemClassName} />}
            />
         </div>
      </aside>
   );
}
