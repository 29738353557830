import WelcomeSlideParentWrapper from './slide-parent-wrapper';

export default function FinalWelcomeSlide({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/accounts _ user, users, profile, account, man, people, website, browser, webpage 1Student.svg"
         title="Easy Payments with Ledga"
         text="Ledga gives complete control over your how you collect payments without worrying about fraudulent transfer or bank charges"
         secondaryText="Now, let's setup your account"
         isFinalSlide={true}
         screen={4}
         setScreen={setScreen}
      />
   );
}
