import Modal from 'components/shared/modals/modal';
import Button from 'components/shared/forms/button';
import { getMerchantUrl } from "api/services/auth.service";
import { useState } from 'react';
import useSWR from 'swr';

export default function SetupCompleteModal({
   modalDisplay,
   toggleModal,
   modalPosition,
}) {
   //styles
   const modalClass =
      'bg-white text-blue-600 min-h-screen w-screen flex flex-col border border-gray items-center md:w-5/6 md:min-h-0 md:rounded-xl md:h-[600px] md:max-w-[500px]';
     
      // const [isLoggedIn, setIsLoggedIn] = useState("");

      // const { data } = useSWR(isLoggedIn ? getMerchantUrl : "", {
      //    onSuccess: onGetMerchantSuccess,
      // });

      // const onSigninSuccess = (data) => {
      //    console.log(data);
      //    setToken(data);
      //    setIsLoggedIn(data);
      // };
      
   return (
      <Modal
         className={modalClass}
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
         modalPosition={modalPosition}
      >
         <div className="mx-auto flex h-screen w-5/6 flex-col items-center justify-center">
            <img
               src="/icons/achievements _ accomplishment, target, mountain, top, flag, man, people, achievement 1Student.svg"
               alt="setup complete"
               className="block w-full md:w-[300px]"
            />
            <h1 className="text-xl font-semibold capitalize text-blue-600">
               setup complete
            </h1>
            <p className="mb-10 text-center font-medium text-slate-400">
               You have successfully created your ledga <br />
               Start collecting payments now
            </p>
            <Button buttonTypeOne to="../../dashboard">
              Go Home
            </Button>
         </div>
      </Modal>
   );
}
