import { useState } from "react";
import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import PageTitle from "components/shared/layout/headers/page-title";
import useSWR from "swr";
import Spinner from "components/shared/feedbacks/spinner";
import FailModal from "components/shared/feedbacks/failure-modal";

export default function UserDetails({
   transferType,
   pageName,
   handleChange,
   state,
   dispatch,
   formValidation,
}) {
   const navigate = useNavigate();

   const [errorModal, setErrorModal] = useState(false);

   const setRecipientName = (value) => {
      dispatch({ type: "UPDATE_INPUT", payload: { recipientName: value } });
   };

   const IdIsValid = state.id.length === 6;
   const { data, error, isValidating } = useSWR(
      IdIsValid ? `merchant/payments/student/verify/${state.id}` : null,
      {
         onSuccess: (data) => {
            setRecipientName(data.name);
         },
         onError: (err) => {
            err?.message.includes("account found")
               ? setRecipientName(err?.message)
               : setErrorModal(true);
         },
      },
   );
   const userLoading = (IdIsValid && !data && !error) || isValidating;

   return (
      <section className="mb-14 md:ml-32 lg:ml-48">
         <IoArrowBack
            onClick={() => navigate("../../../dashboard")}
            className="fixed top-5 left-4 w-12 cursor-pointer text-4xl text-primary md:hidden md:w-8"
         />
         <div className="left container pt-16">
            <PageTitle
               variant="SECONDARY"
               title={`transfer to ${transferType} `}
               message="enter receiver details"
            />
            <img
               src="/icons/profile_icon.svg"
               alt="profile icon"
               className="filter-blue mb-10 block w-20 self-center"
            />
            <div className="mb-16 self-stretch">
               <Input
                  value={state.id.toUpperCase()}
                  name="id"
                  label={`${transferType} id`}
                  onChange={handleChange}
                  autoComplete="off"
                  errorMessage={{
                     requiredError: "id must be 10 digits long",
                     validityError: "please enter a valid id",
                  }}
                  isValid={formValidation.id}
               />

               <Input
                  variant="LINE"
                  value={state.recipientName}
                  name="id"
                  label={`confirm ${transferType} name`}
                  disabled
                  readOnly
                  icon={
                     userLoading && <Spinner color="GREY" size="EXTRA_SMALL" />
                  }
                  iconPosition="RIGHT"
               />
            </div>
            <Button size="WIDE" to={`../../dashboard/${pageName}/2`}>
               continue
            </Button>
         </div>
         <FailModal modalDisplay={errorModal} />
      </section>
   );
}
