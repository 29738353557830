import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormData } from 'utils/hooks/useFormData';
import useRequest from "utils/hooks/useRequest";
import Transfer from 'components/dashboard/transfer/transfer';
import RecieveNumPad from 'components/dashboard/recieve-payment/receive-numpad';
import { receivePayment } from "api/services/merchants.service";
import useFormValidation from "utils/hooks/useFormValidation";


export default function RecievePayment() {
   //TODO: fix page alignment
   //TODO: update modals

   const navigate = useNavigate()

   const initialFormData = {
      id: '',
      amount: '',
      formattedAmount: '',
      paymentPurpose: '',
      studentpin:'',
      merchantpin:'',
   };
   const [state, dispatch] = useFormData(initialFormData);
   const { formValidation, formIsValid } = useFormValidation(state);


   const { step } = useParams();

   const [createPinModal, setCreatePinModal] = useState(false);


   const toggleCreatePinModal = (value) => {
      setCreatePinModal(value);
   };

   const handleNext = () => {
      //save all info in state
      toggleCreatePinModal(true);
   };

   const handleChange = (e) => {
      const {name, value} = e.target
      dispatch({
         type: 'updateInput',
         payload: { name, value }
      });
   };

     //3. get setup function from useRequest hook
     const { queryFn: receiveFn, loading: receiveLoading } =
     useRequest(receivePayment);

      //3c. get data if successful and pass
   //to stateful variables which are in turn used as props
   const onReceiveSuccess = (data) => {
     
   };

   const onReceiveError = (message) => {
      console.log(message);
   };



     //3b. extract data from state and submit
     const handleFormSubmit = () => {
      const queryObj = {
      id: '',
      amount: '',
      formattedAmount: '',
      paymentPurpose: '',
      };

      if (formIsValid) {
         receiveFn(queryObj, {
            onSuccess: onReceiveSuccess,
            onError: onReceiveError,
         });
      }
   };


   const outsideOnClick = () => navigate(-1)

  

   return (
      <>
         <Transfer
            state={state}
            dispatch={dispatch}
            step={Number(step)}
            transferType="student"
            handleSubmit={handleNext}
            pageName="receive-payment"
            handleChange={handleChange}
            formValidation={formValidation}
         />

         <RecieveNumPad 
            createPinModal={createPinModal}
            toggleCreatePinModal={toggleCreatePinModal}
            btnText='home'
            btnOnClick={()=>navigate('../../../dashboard')}
            outsideOnClick={outsideOnClick}
            amount={state.formattedAmount}
            state={state}
            dispatch={dispatch}
           formIsValid={formIsValid}
         />
      </>
   );
}
