import * as REGEX from "utils/constants/regex";
import NumberFormat from "react-number-format";
import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import ArrowBack from "components/shared/layout/nav/arrow-back";
import ModalContainer from "components/shared/modals/modal-container";

export default function AmountModal({
   state,
   dispatch,
   toggleModal,
   modalDisplay,
   handleClick,
   balance,
}) {
   const handleNumberFormat = (values, input, formattedInput) => {
      const { value, formattedValue } = values;
      dispatch({
         type: "updateInput",
         payload: { name: input, value: value },
      });
      dispatch({
         type: "updateInput",
         payload: { name: formattedInput, value: formattedValue },
      });
   };

   return (
      <ModalContainer
         area="THIN"
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
      >
         <ArrowBack handleBack={toggleModal} />
         <div className="container full-page justify-center md:py-24">
            <h2 className="text-2xl mb-10 font-semibold capitalize">
               enter amount
            </h2>

            <p className="mb-3 text-yellow-600 text-lg text-left self-start">
               bal: {balance}
            </p>

            <div className="self-stretch mb-12">
               <NumberFormat
                  customInput={Input}
                  value={state.amount}
                  prefix={"₦"}
                  thousandSeparator={true}
                  label="amount"
                  placeholder="₦"
                  autoFocus={true}
                  errorMessage={{
                     requiredError: "amount cannot be empty",
                     validityError: "please enter a valid amount",
                  }}
                  isValid={REGEX.AMOUNT_VALIDATION.test(state.amount)}
                  onValueChange={(values) =>
                     handleNumberFormat(values, "amount", "formattedAmount")
                  }
               />
               <p className="text-xs pl-1 -mt-4">
                  note that a processing fee of ₦5 is charged by the bank
               </p>
            </div>
            <Button onClick={handleClick}>proceed</Button>
         </div>
      </ModalContainer>
   );
}
