import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormData } from "utils/hooks/useFormData";
import useFormValidation from "utils/hooks/useFormValidation";
import useRequest from "utils/hooks/useRequest";
import useMerchant from "./useMerchant";
import { requestPayout } from "api/services/merchants.service";

export default function usePayoutsRequests(toggleModal, mutateAllRequests) {
   const navigate = useNavigate();

   const initialFormData = {
      amount: "",
      formattedAmount: true,
   };

   const [pin, setPin] = useState("");
   const [state, dispatch] = useFormData(initialFormData);
   const { formIsValid } = useFormValidation(state);

   const [numpadModal, setNumpadModal] = useState(false);
   const [successModal, setSuccessModal] = useState(false);
   const [failureModal, setFailureModal] = useState(false);

   const toggleNumpadModal = () => setNumpadModal((prev) => !prev);
   const toggleSuccessModal = () => setSuccessModal((prev) => !prev);
   const toggleFailureModal = () => setFailureModal((prev) => !prev);

   //queries
   const { accountDetails, ledgerBalance } = useMerchant();

   const {
      queryFn: payoutFn,
      error: payoutError,
      loading: payoutLoading,
   } = useRequest(requestPayout);

   const handleNext = () => {
      toggleModal();
      toggleNumpadModal();
   };

   const handleSubmit = () => {
      if (formIsValid) {
         const amount = state.amount;
         const transaction_pin = pin;
         const queryObj = { amount, transaction_pin };

         payoutFn(queryObj, {
            onSuccess: () => {
               mutateAllRequests();
               toggleSuccessModal();
            },
            onError: () => setFailureModal(true),
         });

         setNumpadModal(false);
         setPin("");
      }
   };

   const handleError = () => {
      navigate("../../../dashboard");
   };

   return {
      state,
      dispatch,
      pin,
      setPin,
      ledgerBalance,
      numpadModal,
      successModal,
      failureModal,
      accountDetails,
      payoutError,
      payoutLoading,
      toggleNumpadModal,
      toggleSuccessModal,
      toggleFailureModal,
      handleNext,
      handleSubmit,
      handleError,
   };
}
