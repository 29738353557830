import useSWR from "swr";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { setToken } from "utils/lib/storage";
import useRequest from "utils/hooks/useRequest";
import { useFormData } from "utils/hooks/useFormData";
import SigninForm from "components/signin/signin-form";
import SplashScreen from "components/shared/layout/splash-screen";
import PageTitle from "components/shared/layout/headers/page-title";
import { signinWithEmailAndPassword } from "api/services/auth.service";
import ResetPassword from "components/dashboard/settings/reset-password/reset-password";
import { getMerchantUrl } from "api/services/auth.service";
import useFormValidation from "utils/hooks/useFormValidation";
import FailModal from "components/shared/feedbacks/failure-modal";

export default function SignIn() {
   const initialFormData = {
      email: "",
      password: "",
   };
   //form states
   const [state, dispatch] = useFormData(initialFormData);
   const { formValidation, formIsValid } = useFormValidation(state);

   //modals' states
   const [resetPasswordModal, setResetPasswordModal] = useState(false);
   const [failureModal, setFailureModal] = useState(false);

   //other states and constantss
   const navigate = useNavigate();
   const [isLoggedIn, setIsLoggedIn] = useState("");
   const [loading, setLoading] = useState("");

   const toggleResetPasswordModal = () => {
      setResetPasswordModal((prev) => !prev);
   };

   //1 get signin function from use request hook
   const { queryFn: signinFn, error: signinError } = useRequest(
      signinWithEmailAndPassword,
   );

   const handleSignin = () => {
      if (formIsValid) {
         setLoading(true);
         signinFn(state, {
            onSuccess: (data) => {
               setToken(data);
               setIsLoggedIn(data);
            },
            onError: () => setFailureModal(true),
         });
      }
   };

   useSWR(isLoggedIn ? getMerchantUrl : null, {
      onSuccess: (data) => {
         if (!data.unique_id) {
            navigate("../setup");
         } else {
            navigate("../dashboard");
         }
      },
      onError: () => setFailureModal(true),
   });

   //Run Failure Modals for network error
   const handleError = () => {
      setFailureModal(false);
      dispatch({ type: "CLEAN_SLATE", payload: initialFormData });
   };

   return (
      <section className="flex min-h-screen items-stretch bg-primary-lightest">
         <SplashScreen />
         <div className="container pt-24">
            <PageTitle
               title="transact with ledga"
               message="sign in"
               variant="TERTIARY"
            />
            <SigninForm
               state={state}
               dispatch={dispatch}
               formValidation={formValidation}
               signinError={signinError}
               signinLoading={loading}
               handleSubmit={handleSignin}
               resetPasswordModal={resetPasswordModal}
               toggleResetPasswordModal={toggleResetPasswordModal}
            />
         </div>
         <ResetPassword
            auth={true}
            modalPosition="CENTER"
            modalDisplay={resetPasswordModal}
            toggleModal={toggleResetPasswordModal}
         />
         <FailModal
            modalPosition="CENTER"
            title="Failed Transaction"
            message={signinError}
            modalDisplay={failureModal}
            btnText="try again"
            btnOnClick={handleError}
         />
      </section>
   );
}

//loading is set to true and not toggled off until the
//the page navigates to another so that spinner continues
//to spin until that point. this is why signinLoading is
//not destructured from the useRequest and used. It stops after
//signinFn runs.
