import { Link } from 'react-router-dom';
import CardItem from './card-item';


export default function CardsGroup({ className }) {
   const cardsClass = ` mt-4 mx-auto w-5/6 mb-10 grid grid-cols-2 gap-3 py-4`;
   const cardItemClass =
      'flex flex-col text-white items-center justify-center bg-blue-600 lg:py-14';
   const iconClass = 'w-12 mb-2 md:text-[40px] md:mb-3';
   const textClass = 'text-center font-medium leading-tight md:text-lg';


   return (
      <section className={cardsClass}>
         <Link to="receive-payment/1">
            <CardItem className={cardItemClass}>
               <img
               src="/icons/receive.svg"
               alt="profile icon"
               className={iconClass}
            />
               <p className={textClass}>
                  receive <br /> payment
               </p>
            </CardItem>
         </Link>

         <Link to="payout-request">
            <CardItem className={cardItemClass}>
               <img
               src="/icons/withdraw.svg"
               alt="profile icon"
               className={iconClass}
            />
               <p className={textClass}>
                  request <br /> payouts
               </p>
            </CardItem>
         </Link>

      </section>
   );
}
