import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import FormGroup from "components/shared/forms/form-group";
import Spinner from "components/shared/feedbacks/spinner";
import FormErrorAlert from "components/shared/feedbacks/form-error";
import useFormValidation from "utils/hooks/useFormValidation";
import { getOnchangeHandler } from "utils/lib/getOnchangeHandler";

export default function SigninForm({
   state,
   dispatch,
   signinError,
   handleSubmit,
   signinLoading,
   formValidation,
   toggleResetPasswordModal,
}) {
   const handleChange = getOnchangeHandler(dispatch);
   const { formIsValid } = useFormValidation(state);

   return (
      <div className="w-full">
         <FormGroup className="mb-20">
            <Input
               name="email"
               label="email"
               value={state.email}
               onChange={handleChange}
               errorMessage={{
                  requiredError: "email cannot be empty",
                  validityError: "please enter a valid email",
               }}
               isValid={formValidation.email}
            />

            <Input
               value={state.password}
               type="password"
               name="password"
               label="password"
               onChange={handleChange}
               isValid={formValidation.password}
            />
            {signinError?.includes("Invalid sign in details") && (
               <FormErrorAlert formErrorMessage="username or password incorrect" />
            )}
         </FormGroup>
         <div className="mt-12 flex items-center justify-between">
            <Button
               onClick={handleSubmit}
               disabled={signinLoading || !formIsValid}>
               {signinLoading ? (
                  <span className="px-[18px]">
                     <Spinner color="WHITE" />
                  </span>
               ) : (
                  "sign in"
               )}
            </Button>

            <Button
               size="ZERO"
               variant="TERTIARY"
               color="BLUE_TEXT"
               onClick={toggleResetPasswordModal}
               disabled={signinLoading}>
               forgot password?
            </Button>
         </div>
      </div>
   );
}
