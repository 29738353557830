import useSWR from "swr";
import { useState, useEffect } from "react";

import { getMerchantUrl } from "api/services/auth.service";
import { getFormattedAmount } from "utils/lib/number-formatter";

export default function useMerchant(config) {
   const [merchantBankDetails, setMerchantBankDetails] = useState({});
   const [merchantId, setMerchantId] = useState("");
   const [ledgerBalance, setLedgerBalance] = useState("");

   const {
      data: merchant,
      error: getMerchantError,
      mutate,
   } = useSWR(getMerchantUrl, config);

   useEffect(() => {
      if (merchant?.bank_details) {
         const {
            bank_name: bankName,
            account_name: accountName,
            account_number: accountNumber,
         } = merchant.bank_details;

         setMerchantId(merchant.unique_id);
         setMerchantBankDetails({ bankName, accountName, accountNumber });
         setLedgerBalance(getFormattedAmount(merchant.meta.balance));
      }
   }, [merchant]);

   const getMerchantLoading = !merchant && !getMerchantError;
   return {
      merchantId,
      merchant,
      ledgerBalance,
      merchantBankDetails,
      getMerchantError,
      getMerchantLoading,
      mutate,
   };
}
