import { Link } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { classNames } from "utils/lib/getClassName";

const VARIANT_MAPS = {
   PRIMARY: "absolute left-6 sm:left-16 md:hidden",
   SECONDARY: "absolute left-6 sm:left-16 md:left-40 lg:left-64",
   TERTIARY: "absolute left-12 sm:left-20 md:sticky -ml-6",
};

const COLOR_MAPS = {
   WHITE: "text-white",
   BLUE: "text-primary",
   GREY: "text-secondary-darker",
};

export default function ArrowBack({
   to,
   handleBack,
   background,
   color = "BLUE",
   variant = "PRIMARY",
}) {
   const className = classNames(
      `self-start absolute w-12 top-6 md:w-8 cursor-pointer text-4xl
      ${background === "BLUE" ? "text-white" : "text-primary"}`,
      COLOR_MAPS[color],
      VARIANT_MAPS[variant],
   );

   if (to) {
      return (
         <Link to={to} className={className}>
            <IoArrowBack onClick={handleBack} />
         </Link>
      );
   } else {
      return (
         <div className={className}>
            <IoArrowBack onClick={handleBack} />
         </div>
      );
   }
}
