import ResetForm from "./reset-form";
import * as REGEX from "utils/constants/regex";
import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import PageTitle from "components/shared/layout/headers/page-title";
import ModalContainer from "components/shared/modals/modal-container";
import EmailSuccess from "../../../shared/feedbacks/email-success";
import VerificationModal from "components/shared/modals/verification-modal";
import SuccessFeedbackModal from "components/shared/feedbacks/success-modal";
import Spinner from "components/shared/feedbacks/spinner";
import ArrowBack from "components/shared/layout/nav/arrow-back";
import FailModal from "components/shared/feedbacks/failure-modal";
import useResetPassword from "api/hooks/useResetPassword";

export default function ResetPassword({
   modalPosition,
   auth,
   modalDisplay,
   toggleModal,
}) {
   const {
      state,
      dispatch,
      formValidation,
      otpSuccessModal,
      resetFormModal,
      verificationModal,
      resetSuccessModal,
      setVerificationCode,
      otpVerificationErrorMessage,
      emailSubmitLoading,
      otpVerificationLoading,
      passwordResetLoading,
      verificationCode,
      errorModal,
      modalContainer,
      toggleModalContainer,
      navigate,
      handleEmailSubmit,
      handleVerification,
      handleSubmit,
      setOtpSuccessModal,
      setResetFormModal,
      setVerificationModal,
      setResetSuccessModal,
      setOTPVerificationErrorMessage,
   } = useResetPassword(auth, modalDisplay, toggleModal);

   return (
      <>
         <ModalContainer
            modalPosition={modalPosition}
            modalDisplay={modalContainer}
            toggleModal={toggleModalContainer}
            outsideOnClick={
               !auth ? () => navigate("../../settings") : toggleModalContainer
            }
         >
            <ArrowBack
               handleBack={
                  !auth
                     ? () => navigate("../../../dashboard/settings")
                     : toggleModalContainer
               }
            />
            <div className="mx-auto mt-24 flex w-5/6 max-w-sm flex-col items-center bg-white text-blue-600 md:mt-0 md:py-14">
               <PageTitle variant="TERTIARY" subtitle="reset password" />
               <div className="my-24 self-stretch">
                  <Input
                     value={state.email}
                     name="email"
                     label="email"
                     variant="LINE"
                     onChange={(e) =>
                        dispatch({
                           type: "updateInput",
                           payload: {
                              name: e.target.name,
                              value: e.target.value,
                           },
                        })
                     }
                     errorMessage={{
                        requiredError: "email cannot be empty",
                        validityError: "please enter a valid email",
                     }}
                     isValid={REGEX.EMAIL_VALIDATION.test(state.email)}
                  />
               </div>
               <Button size="WIDE" onClick={handleEmailSubmit}>
                  {emailSubmitLoading ? (
                     <Spinner color="WHITE" />
                  ) : (
                     "reset password"
                  )}
               </Button>
            </div>
         </ModalContainer>
         <EmailSuccess
            btnText="next"
            successModal={otpSuccessModal}
            modalPosition={modalPosition}
            message="Check your mailbox for a reset otp"
            btnOnClick={() => setVerificationModal(true)}
            toggleSuccessModal={() => setOtpSuccessModal((prev) => !prev)}
         />
         <VerificationModal
            value={verificationCode}
            setValue={setVerificationCode}
            modalPosition={modalPosition}
            loading={otpVerificationLoading}
            modalDisplay={verificationModal}
            handleVerification={handleVerification}
            errorMessage={otpVerificationErrorMessage}
            setErrorMessage={setOTPVerificationErrorMessage}
         />
         <ResetForm
            state={state}
            dispatch={dispatch}
            modalDisplay={resetFormModal}
            handleSubmit={handleSubmit}
            modalPosition={modalPosition}
            loading={passwordResetLoading}
            formValidation={formValidation}
            toggleModal={() => setResetFormModal((prev) => !prev)}
         />
         <FailModal modalDisplay={errorModal} />
         <SuccessFeedbackModal
            modalPosition={modalPosition}
            title="password reset successful"
            modalDisplay={resetSuccessModal}
            toggleModal={() => setResetSuccessModal(!resetSuccessModal)}
            btnText={auth ? "continue" : "home"}
            to={auth ? "../dashboard" : "../../../dashboard"}
         />
      </>
   );
}
