import ArrowBack from "components/shared/layout/nav/arrow-back";
import ModalContainer from "components/shared/modals/modal-container";

export default function TransactionDetails({
   children,
   modalDisplay,
   toggleModal,
   handleBack,
   title,
   sender,
   recipient,
   amount,
   status,
   totalAmount,
   transactionType,
}) {
   //styles
   const wrapper = `
      bg-primary  
      px-10 pb-0 pt-20
      font-semibold capitalize text-white 
      md:py-12
   `;
   const innerWrapper = `
      mx-auto flex flex-col items-center 
      md:shadow
      md:max-w-sm 
      md:rounded-tl-xl md:rounded-tr-xl 
   `;
   const divClass = `
      mb-6
      text-lg 
      w-5/6 max-w-[350px] 
      grid grid-cols-2 
   `;
   const amountClass = `
      p-4 
      text-xl 
      bg-primary-lighter 
      rounded-xl 
      sm:text-3xl
   `;

   return (
      <ModalContainer modalDisplay={modalDisplay} toggleModal={toggleModal}>
         <ArrowBack handleBack={handleBack} color="WHITE" />
         <div className={wrapper}>
            <h2 className="self-start text-xl sm:ml-8 md:hidden">{title}</h2>
            <div className={innerWrapper}>
               <div className={divClass}>
                  <p>sender:</p>
                  <div>
                     <p className="text-base sm:text-xl">{sender?.id}</p>
                     <p className="text-base sm:text-xl">
                        {sender?.accountNumber}
                     </p>
                     <p className="text-right text-xs sm:text-sm">
                        {sender?.bankName}
                     </p>
                  </div>
               </div>

               <div className={divClass}>
                  <p>recipient:</p>
                  <div>
                     <p className="text-base sm:text-xl">{recipient?.id}</p>
                     <p className="text-base sm:text-xl">
                        {recipient?.accountNumber}
                     </p>
                     <p className="text-right text-xs sm:text-sm">
                        {recipient?.bankName}
                     </p>
                  </div>
               </div>

               <div className={divClass}>
                  <p>status:</p>
                  <p>{status}</p>
               </div>
            </div>
            <div className="mt-6 flex flex-col items-center justify-center">
               <p className={amountClass}>{amount}</p>
               {transactionType === "funding" && (
                  <p className="text-xs md:text-sm">
                     total paid: {totalAmount}
                  </p>
               )}
            </div>
         </div>
         {children}
      </ModalContainer>
   );
}
