import TableCol from "components/shared/tables/table-col";
import TableRow from "components/shared/tables/table-row";
import { MdCallMade } from "react-icons/md";
import { BsBoxArrowInDown } from "react-icons/bs";
import { Link } from "react-router-dom";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { getFormattedDate } from "utils/lib/number-formatter";

export const FallbackComponent = ({ transactionType }) => {
   return (
      <div className="text-sm text-secondary">
         your {transactionType} history will appear here once you have completed{" "}
         {transactionType} transactions
      </div>
   );
};

export default function PaymentHistory({
   className,
   transactionType,
   transactionData,
}) {
   //styles
   const wrapper = `
      ${className} 
      w-5/6 
      mx-auto mb-10 
      flex flex-col 
      sm:text-xl
      md:px-5  
      lg:text-base
   `;
   const accountNumber = `
      self-start 
      text-gray-600 font-semibold text-sm 
      sm:text-base
      md:text-lg 
      lg:text-xl
   `;
   const bankName = `
      self-start 
      text-gray-400 text-xs 
      sm:text-sm
   `;
   const date = `
      justify-center 
      text-gray-400 text-xs 
      sm:text-sm 
      md:text-base 
      lg:text-xs
   `;
   const amount = `
      ${transactionType === "payout" ? "text-red-500" : "text-green-500"}
      justify-end font-semibold 
   `;
   const link = `
      mt-4 
      self-end 
      cursor-pointer 
      text-yellow-500 underline text-xs font-semibold
      hover:text-yellow-400  
      sm:text-sm 
      lg:text-xs
   `;

   return (
      <section className={wrapper}>
         <h2 className="text-xl font-semibold text-gray-500">
            {transactionType === "payout"
               ? "payout history"
               : "transfer history"}
         </h2>
         <h3 className="text-md font-semibold text-gray-400">
            recent transactions
         </h3>
         {transactionData.length < 1 ? (
            <FallbackComponent transactionType={transactionType} />
         ) : (
            <div>
               {transactionData?.slice(0, 4).map((i) => (
                  <TableRow key={i._id}>
                     <TableCol className="flex-col">
                        <p className={accountNumber}>
                           {i.transaction_type === "payout"
                              ? i?.paid_to?.accountNumber
                              : i.from}
                        </p>
                        <p className={bankName}>
                           {i.transaction_type === "payout"
                              ? i?.paid_to.bankName
                              : ""}
                        </p>
                     </TableCol>
                     <TableCol className={date}>
                        {getFormattedDate(i.created)}
                     </TableCol>
                     <TableCol className="justify-center text-gray-400">
                        {i.transaction_type === "payout" ? (
                           <MdCallMade />
                        ) : (
                           <BsBoxArrowInDown />
                        )}
                     </TableCol>
                     <TableCol className={amount}>
                        {getFormattedAmount(i.amount)}
                     </TableCol>
                  </TableRow>
               ))}
               <Link to="/dashboard/transactions" className={link}>
                  see full list &gt;{" "}
               </Link>
            </div>
         )}
      </section>
   );
}
