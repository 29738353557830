import TableRow from "../tables/table-row";
import TableCol from "../tables/table-col";

export default function ReceiptField({ label, value }) {
   if (!value) {
      return;
   }

   return (
      <TableRow gridTemplateCols="grid-cols-2">
         <TableCol className="text-sm capitalize">{label}:</TableCol>
         <TableCol className="justify-end text-sm">{value}</TableCol>
      </TableRow>
   );
}
