import { useState } from "react";
import TableRow from "components/shared/tables/table-row";
import TableCol from "components/shared/tables/table-col";
import { MdCallMade } from "react-icons/md";
import { BsBoxArrowInDown } from "react-icons/bs";
import getMonthName from "utils/lib/getMonthName";
import TransactionReceipt from "components/shared/receipt/transaction-receipt";

export default function TransactionsMonth({ month }) {
   const monthName = getMonthName(Object.keys(month)[0]);
   const monthData = Object.values(month)[0];

   const [activeModal, setActiveModal] = useState("");

   return (
      <>
         <h3 className="inline-block self-center rounded-xl bg-blue-600 px-2 font-semibold text-white sm:rounded-3xl sm:px-3  sm:py-1 sm:text-xl lg:text-2xl">
            {monthName}
         </h3>
         <div className="mb-6 md:mb-12">
            {monthData.map((i) => {
               return (
                  <div key={i._id}>
                     <div onClick={() => setActiveModal(i._id)}>
                        <TableRow
                           gridTemplateCols="grid-cols-custom-table-1"
                           className="cursor-pointer hover:bg-blue-50">
                           <TableCol className="justify-start font-medium text-secondary-darker">
                              {i.transaction_type}
                           </TableCol>
                           <TableCol className="justify-center text-xs font-medium text-secondary-darker sm:text-sm md:text-base lg:text-lg">
                              {i.formattedDate}
                           </TableCol>
                           <TableCol className="justify-center font-medium text-secondary-darker">
                              {i.isCreditTransaction ? (
                                 <BsBoxArrowInDown />
                              ) : (
                                 <MdCallMade />
                              )}
                           </TableCol>
                           <TableCol
                              className={`justify-end font-medium ${
                                 i.isCreditTransaction
                                    ? "text-green-500"
                                    : "text-red-500"
                              }`}>
                              {i.formattedAmount}
                           </TableCol>
                        </TableRow>
                     </div>

                     <TransactionReceipt
                        modalDisplay={activeModal === i._id}
                        toggleModal={() => setActiveModal("")}
                        transaction={i}
                     />
                  </div>
               );
            })}
         </div>
      </>
   );
}
