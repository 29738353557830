import * as REGEX from 'utils/constants/regex';
import NumberFormat from 'react-number-format';
import Input from 'components/shared/forms/input';
import Button from 'components/shared/forms/button';
import FormGroup from 'components/shared/forms/form-group';
import SplashScreen from 'components/shared/forms/splash-screen';
import PageTitle from 'components/shared/layout/headers/page-title';

export default function PersonalDetailsForm({
   state,
   dispatch,
   setScreen,
   formValidation,
}) {
   const handleChange = (e) => {
      const {name, value} = e.target
      dispatch({
         type: 'updateInput',
         payload: { name, value }
      });
   };



   const handleSubmit = () => {
      setScreen((prev) => prev + 1);
   };

   return (
      <section className='mx-auto flex min-h-screen'>
         <SplashScreen />
         <div className="container pt-24">
            <PageTitle
               variant='TERTIARY' 
               title="Business Details"
               message="Provide basic business information to get started"
            />
            <FormGroup className="self-stretch">
               <Input
                  value={state.storeName}
                  name="storeName"
                  label="store name"
                  onChange={handleChange}
                  errorMessage={{
                     requiredError: 'store name cannot be empty',
                     validityError: 'please enter a valid store name'
                  }}
                  isValid={formValidation.storeName}
                  />
               <Input
                  value={state.address}
                  name="address"
                  label="address"
                  onChange={handleChange}
                  errorMessage={{
                     requiredError:'address cannot be empty',                    
                     validityError:'please enter a valid address'
                  }}
                  isValid={formValidation.address}
               />
            
            <Input
                  value={state.ownerName}
                  name="ownerName"
                  label="owner name"
                  onChange={handleChange}
                  errorMessage={{
                     requiredError: 'owner name cannot be empty',
                     validityError: 'please enter a valid name'
                  }}
                  isValid={formValidation.ownerName}
               />

            </FormGroup>
            <Button onClick={handleSubmit}>
               next
            </Button>
         </div>
      </section>
   );
}


//NumberFormat is used to format date of birth values as they come in. 
//the Input is simply passed in as a prop value of the customInput prop of
//NumberFormat template in order for the styles to be inherited. All props 
//relating to the originally designed Input is passed is passed together directly
//into NumberFormat