import { axios } from "utils/lib/axios";
import { clearToken } from "utils/lib/storage";

export const completeSignup = async (queryObj) => {
   return axios.post("merchant/setup/process", queryObj);
};

export const signinWithEmailAndPassword = async (queryObj) => {
   return axios.post("merchant/login", queryObj);
};

export const getMerchantUrl = () => "merchant/account/info";

export const signOut = () => {
   window.location.href = "/";
   clearToken();
};
