import { IoWarning } from "react-icons/io5";

export default function FormErrorAlert({ formErrorMessage }) {
   if (!formErrorMessage) {
      return <></>;
   }

   return (
      <div
         role="alert"
         className="absolute w-full text-sm text-red-700 border-l-4 pl-4 border-red-700 flex items-center"
      >
         <IoWarning className="text-2xl" />
         <span className="pl-2">{formErrorMessage}</span>
      </div>
   );
}
