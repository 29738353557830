import { useState } from "react";
import TableRow from "components/shared/tables/table-row";
import TableCol from "components/shared/tables/table-col";
import getMonthName from "utils/lib/getMonthName";
import { getFormattedAmount } from "utils/lib/number-formatter";
import { getFormattedDate } from "utils/lib/number-formatter";
import RequestDetails from "./request-details";

export default function RequestsTableMonths({ month }) {
   const monthName = getMonthName(Object.keys(month)[0]);
   const monthData = Object.values(month)[0];

   const [activeModal, setActiveModal] = useState("");

   const getStatusColor = (status) => {
      if (status === "pending") {
         return "text-yellow-500";
      } else if (status === "cancelled") {
         return "text-red-500";
      } else {
         return "text-green-500";
      }
   };

   return (
      <>
         <h3 className="inline-block self-center rounded-xl bg-blue-600 px-2 font-semibold text-white sm:rounded-3xl sm:px-3  sm:py-1 sm:text-xl lg:text-2xl">
            {monthName}
         </h3>
         <div className="mb-3">
            {monthData.map((i) => (
               <div key={i._id}>
                  <div onClick={() => setActiveModal(i._id)}>
                     <TableRow
                        gridTemplateCols="grid-cols-3 sm:grid-cols-4"
                        key={i._id}
                        className="cursor-pointer hover:bg-blue-50">
                        <TableCol className="hidden justify-start font-medium text-secondary-darker sm:block">
                           <p className="text-sm font-semibold text-gray-600 sm:text-base lg:text-lg">
                              076544352
                           </p>
                           <p className="text-xs text-gray-400">Kuda User</p>
                        </TableCol>
                        <TableCol className="justify-start font-medium text-secondary-darker sm:justify-center">
                           {getFormattedDate(i.created)}
                        </TableCol>
                        <TableCol className="justify-center font-medium text-secondary-darker">
                           {getFormattedAmount(i.amount)}
                        </TableCol>

                        <TableCol
                           className={`${getStatusColor(
                              i.status,
                           )} justify-end font-medium `}>
                           {i.status}
                        </TableCol>
                     </TableRow>
                  </div>
                  <RequestDetails
                     modalDisplay={activeModal === i._id}
                     toggleModal={() => setActiveModal("")}
                     handleBack={() => setActiveModal("")}
                     title="payout request history"
                     status={i.status}
                     sender={{ id: i.to }}
                     recipient={{
                        accountNumber: i.paid_to.accountNumber,
                        bankName: i.paid_to.accountName,
                     }}
                     requestId={i._id}
                     amount={getFormattedAmount(i.amount)}
                     setActiveModal={setActiveModal}
                  />
               </div>
            ))}
         </div>
      </>
   );
}
