import { Link } from "react-router-dom";

export default function LinkItem({ className, icon, label, to, btnOnclick }) {
   return (
      <div
         className={`${className} mb-6 flex items-center border-none text-lg font-medium capitalize text-gray-500 sm:text-xl md:mb-7 lg:text-[22px] lg:font-normal`}
      >
         {icon}
         {to ? (
            <Link to={to}>{label}</Link>
         ) : (
            <button onClick={btnOnclick}>{label}</button>
         )}
      </div>
   );
}
