import { axios } from "utils/lib/axios";

export const updateBankDetails = async (queryObj) => {
   return axios.put("merchant/bank-details", queryObj);
};

export const createTransactionPin = async (queryObj) => {
   return axios.post("merchant/setup/transaction-pin", queryObj);
};

export const changeTransactionPin = async (queryObj) => {
   return axios.patch("merchant/security/transaction-pin", queryObj);
};

export const resetPasswordEmail = async (queryObj) => {
   return axios.post("merchant/password-reset/email", queryObj);
};

export const resetPasswordOTP = async (queryObj) => {
   return axios.post("merchant/password-reset/otp", queryObj);
};

export const resetPasswordNewPassword = async (queryObj) => {
   return axios.patch("merchant/password-reset/new-password", queryObj);
};
