import { useState } from "react";
import Button from "components/shared/forms/button";
import WelcomeSlideOne from "components/welcome/slide-1";
import WelcomeSlideTwo from "components/welcome/slide-2";
import WelcomeSlideThree from "components/welcome/slide-3";
import FinalWelcomeSlide from "components/welcome/final-slide";

function Welcome() {
   const [screen, setScreen] = useState(0);

   switch (screen) {
      case 4:
         return <FinalWelcomeSlide setScreen={setScreen} />;

      case 3:
         return <WelcomeSlideThree setScreen={setScreen} />;

      case 2:
         return <WelcomeSlideTwo setScreen={setScreen} />;

      case 1:
         return <WelcomeSlideOne setScreen={setScreen} />;

      default:
         return (
            <section
               role="alert"
               className="bg-white w-full min-h-screen flex items-center justify-center py-10"
            >
               <div className="text-center max-w-xs p-2">
                  <img
                     src="/icons/social media _ social network, like, thumbs up, approve, man, people, complete 1Admin.png"
                     alt="Icon"
                     className="mx-auto w-full md:w-48 mb-4"
                  />

                  <h1 className="capitalize text-2xl text-blue-600 font-bold">
                     Welcome to Ledga
                  </h1>

                  <p className="text-stone-700 mt-1 md:text-sm">
                     The financial tool that allows you to create your own
                     financial system, monitor it and earn money from it
                  </p>

                  <Button
                     onClick={() => setScreen((prev) => prev + 1)}
                     className="mt-16"
                  >
                     <span className="px-10">Get started</span>
                  </Button>
               </div>
            </section>
         );
   }
}

export default Welcome;
