import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import PageTitle from "components/shared/layout/headers/page-title";
import EmailSuccess from "../../../shared/feedbacks/email-success";
import FormGroup from "components/shared/forms/form-group";

export default function ContactUs() {
   const navigate = useNavigate();
   const [name, setName] = useState("");
   const [email, setEmail] = useState("");
   const [body, setBody] = useState("");

   const [successModal, setSuccessModal] = useState(false);
   const toggleSuccessModal = (value) => setSuccessModal(value);

   const handleSubmit = () => {
      toggleSuccessModal(true);
      setTimeout(() => {
         navigate("../../../dashboard");
      }, 3000);
      return () => clearTimeout;
   };

   return (
      <section className="wrapper">
        <IoArrowBack
                  onClick={() =>  navigate("../../../dashboard/settings")}
                  className="fixed top-5 left-4 w-12 text-primary cursor-pointer text-4xl md:hidden md:w-8"
               />
         <div className="container left md:py-6 pt-16">
            <PageTitle
               variant="SECONDARY"
               title="contact us"
               message="send us your feedback"
            />
            <form
               action="mailto:hello@ledgafinance.com"
               method="post"
               className="mb-4"
            >
               <Input
                  value={name}
                  variant="LINE"
                  type="text"
                  name="name"
                  label="name"
                  onChange={(e) => setName(e.target.value)}
               />
               <Input
                  value={email}
                  variant="LINE"
                  type="text"
                  name="email"
                  label="email"
                  onChange={(e) => setEmail(e.target.value)}
               />
               <label>
                  Feedback:
                  <textarea
                     value={body}
                     name="feedback"
                     rows="5"
                     cols="35"
                     className="border-2 border-primary rounded-lg w-full p-4"
                     onChange={(e) => setBody(e.target.value)}
                  >
                     {body}
                  </textarea>
               </label>
            </form>
            <FormGroup className="w-full flex justify-between">
               <Button disabled={body.length < 3} onClick={handleSubmit}>
                  send
               </Button>
               <Button
                  disabled={body.length < 3}
                  size="ZERO"
                  variant="TERTIARY"
                  color="BLUE_TEXT"
                  onClick={() => setBody("")}
               >
                  reset
               </Button>
            </FormGroup>
            <EmailSuccess
               successModal={successModal}
               toggleSuccessModal={toggleSuccessModal}
               modalPosition="CENTER"
               outsideOnClick={() =>
                  body.length > 3 && navigate("../../../dashboard")
               }
            />
         </div>
      </section>
   );
}
