import NumPadModal from "components/shared/numpad/numpad-modal";
import LoadingModal from "components/shared/feedbacks/loading-modal";
import FailModal from "components/shared/feedbacks/failure-modal";
import SuccessFeedbackModal from "components/shared/feedbacks/success-modal";
import { IoCheckmarkCircleOutline } from "react-icons/io5";
import RequestPayoutForm from "components/dashboard/requests/request-payout-form";
import usePayoutsRequests from "api/hooks/usePayoutRequests";
import {useState} from "react";
import useAllRequests from "api/hooks/useAllRequests";

export default function PayoutRequest() {

   const [requestModal, setRequestModal] = useState(true);
   const toggleRequestModal = () => setRequestModal((prev) => !prev);


   const {
      state,
      dispatch,
      pin,
      setPin,
      ledgerBalance,
      numpadModal,
      successModal,
      failureModal,
      accountDetails,
      payoutError,
      payoutLoading,
      toggleNumpadModal,
      toggleSuccessModal,
      handleNext,
      handleSubmit,
      handleError,
   } = usePayoutsRequests(toggleRequestModal);

   return (
      <>
         <RequestPayoutForm
            state={state}
            dispatch={dispatch}
            modalDisplay={requestModal}
            toggleModal={toggleRequestModal}
            handleNext={handleNext}
            accountDetails={accountDetails}
            balance={ledgerBalance}
         />
         <NumPadModal
            pin={pin}
            setPin={setPin}
            background="BLUE"
            modalDisplay={numpadModal}
            toggleModal={toggleNumpadModal}
            btnText="proceed"
            title="transaction pin"
            message="confirm payout approval"
            amount={state.formattedAmount}
            btnOnClick={handleSubmit}
            togglePrevModal={toggleRequestModal}
         />
         <LoadingModal
            modalDisplay={payoutLoading}
            message="please wait while the transaction is being processed"
         />
         <SuccessFeedbackModal
            bg="BLUE"
            modalDisplay={successModal}
            toggleModal={toggleSuccessModal}
            icon={<IoCheckmarkCircleOutline />}
            title="Payout Requested"
            message="You will receive  your payout in your account once it is approved"
            btnText="ok"
            btnOnClick={toggleSuccessModal}
         />
         <FailModal
            title="Failed Payout"
            message={payoutError}
            modalDisplay={failureModal}
            btnText="try again"
            btnOnClick={handleError}
         />
      </>
   );
}
