import { useState } from 'react';
import * as REGEX from 'utils/constants/regex';
import Input from 'components/shared/forms/input';
import Button from 'components/shared/forms/button';
import SplashScreen from 'components/shared/forms/splash-screen';
import PageTitle from 'components/shared/layout/headers/page-title';
import { IoEyeOutline } from 'react-icons/io5';
import { IoEyeOffOutline } from 'react-icons/io5';


export default function CreatePasswordForm({
   state,
   dispatch,
   setScreen,
   formValidation,
}) {
   // TODO: add type to password, add function to input for showing password
   // TODO: send data to backend from here?
   const [showPassword, setShowPassword] = useState(false)
   const [showConfirmPassword, setShowConfirmPassword] = useState(false)

   const handleChange = (e) => {
      const {name, value} = e.target
      dispatch({
         type: 'updateInput',
         payload: {name, value},
      });
   };

   const handleNext = () =>{
      setScreen(prev=>prev+1)
   }

 
   return (
      <section className='mx-auto flex min-h-screen'>
         <SplashScreen />
         
         <div className="container pt-24">
            <PageTitle 
               variant="TERTIARY"
               title="create password"
               message="Create a unique password for your ledga"
            />
            <form className="self-stretch my-10" autoComplete="new-password">
               <input type='hidden' value='something'/>
               <Input
                  value={state.password}
                  name="password"
                  label="password"
                  iconPosition='RIGHT'
                  showPassword={showPassword}
                  autoComplete="new-password"
                  type={showPassword ? 'text' : 'password'}
                  onChange={handleChange}
                  iconOnClick={()=>setShowPassword(prev=>!prev)}
                  icon={
                     showPassword 
                     ? <IoEyeOffOutline className='text-2xl'/> 
                     : <IoEyeOutline className='text-2xl'/>
                  }
                  errorMessage={{
                     requiredError: `password must be at least 8 digits long,\ncontain at least 1 uppercase & lowercase character`,
                     validityError: `password must be at least 8 digits long,\ncontain at least 1 uppercase & lowercase character`
                  }}
                  isValid={formValidation.password}
               />
               <Input
                  value={state.passwordConfirmation}
                  name="passwordConfirmation"
                  label="confirm password"
                  iconPosition='RIGHT'
                  autoComplete="new-password"
                  onChange={handleChange}
                  showPassword={showConfirmPassword}
                  iconOnClick={()=>setShowConfirmPassword(prev=>!prev)}
                  type={showConfirmPassword ? 'text' : 'password'}
                  icon={
                     showConfirmPassword 
                     ? <IoEyeOffOutline className='text-2xl'/>
                     : <IoEyeOutline className='text-2xl'/>
                  }
                  errorMessage={{
                     requiredError: 'please enter a matching password',
                     validityError: 'please enter a matching password'
                  }}
                  isValid={formValidation.passwordConfirmation}
               />
            </form>
            <Button onClick={handleNext}>next</Button>
         </div>
      </section>
   );
}
