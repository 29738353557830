import { axios } from 'utils/lib/axios';

export const getBanks = async () => {
   return axios.get('payment/banks');
};

export const getNubanName = async (queryObj) => {
   return axios.post('payment/nuban-name-enquiry', queryObj);
};

export const getTransactionsUrl = 'merchant/transactions';

export const getBanksUrl = 'payment/banks';

export const getNubanUrl = 'payment/nuban-name-enquiry';

export const getAllRequestsUrl = "merchant/payouts/history";

export const cancelPayoutRequest = async (queryObj) => {
   return axios.put("student/payouts/request/cancel", queryObj);
};

