import { useState, useCallback, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useFormData } from "utils/hooks/useFormData";
import BankDetailsForm from "components/setup/bank-details-form";
import ModalContainer from "components/shared/modals/modal-container";
import SuccessFeedbackModal from "components/shared/feedbacks/success-modal";
import useFormValidation from "utils/hooks/useFormValidation";
import useRequest from "utils/hooks/useRequest";
import { updateBankDetails } from "api/services/settings.service";
import EnterPassword from "./enter-password";
import FailModal from "components/shared/feedbacks/failure-modal";

export default function SetBankDetailsModal() {
   const navigate = useNavigate();
   const initialFormData = {
      accountNumber: "",
      bankName: "",
      bankCode: "",
      accountName: "",
      password: "",
   };
   //form state
   const [state, dispatch] = useFormData(initialFormData);
   const { formValidation, formIsValid } = useFormValidation(state);

   //modals state
   const [successModal, setSuccessModal] = useState(false);
   const [errorModal, setErrorModal] = useState(false);
   const [bankDetailsDisplay, setBankDetailsDisplay] = useState(true);
   const [enterPasswordModal, setEnterPasswordModal] = useState(false);
   const [errorMessage, setErrorMessage] = useState("");
   const [errorTitle, setErrorTitle] = useState("");

   //queryFunctions
   const { queryFn: updateFn, loading: updateLoading } =
      useRequest(updateBankDetails);

   //handleFunctions
   const handleSubmit = () => {
      if (formIsValid) {
         const queryObj = {
            bank_name: state.bankName,
            bank_code: state.bankCode,
            account_name: state.accountName,
            account_number: state.accountNumber,
            password: state.password,
         };

         updateFn(queryObj, {
            onSuccess: () => {
               setEnterPasswordModal(false);
               setSuccessModal(true);
               dispatch({ type: "CLEAN_SLATE", payload: initialFormData });
            },

            onError: (err) => {
               setEnterPasswordModal(false);
               setErrorModal(true);

               if (err?.includes("password you entered is incorrect")) {
                  setErrorMessage(err);
               } else {
                  setErrorMessage(
                     `bank details' update could not be completed\nplease check your network settings and try again`,
                  );
               }
            },
         });
      }
   };

   //wrapped in useCallback because it is called in
   //a useEffect in useBankDetails hook and used as dependency
   const handleBankFormError = useCallback(
      (
         message = `Could not fetch bank details.
      Please check your network and try again`,
         title = "something went wrong",
      ) => {
         setErrorModal(true);
         setErrorMessage(message);
         setErrorTitle(title);
      },
      [],
   );

   const handleClick = () => {
      setBankDetailsDisplay(false);
      setEnterPasswordModal(true);
   };

   const handleBack = () => {
      navigate("../../settings");
   };

   const handleRetry = () => {
      setBankDetailsDisplay(true);
      setErrorModal(false);
      navigate("../../settings/bank-details");
   };

   return (
      <Fragment>
         <ModalContainer
            modalDisplay={bankDetailsDisplay}
            outsideOnClick={handleBack}
         >
            <div className="translate-y-[70px] md:translate-y-0">
               <BankDetailsForm
                  state={state}
                  dispatch={dispatch}
                  message="change payout account"
                  btnText="next"
                  title={errorTitle}
                  formValidation={formValidation}
                  handleSubmit={handleClick}
                  handleError={handleBankFormError}
                  handleBack={handleBack}
               />
            </div>
         </ModalContainer>
         <EnterPassword
            state={state}
            dispatch={dispatch}
            formValidation={formValidation}
            loading={updateLoading}
            btnText="add account"
            handleSubmit={handleSubmit}
            modalDisplay={enterPasswordModal}
            toggleModal={() => setEnterPasswordModal((prev) => !prev)}
         />
         <FailModal
            modalDisplay={errorModal}
            message={errorMessage}
            btnOnClick={handleRetry}
         />
         <SuccessFeedbackModal
            modalDisplay={successModal}
            toggleModal={() => setSuccessModal((prev) => !prev)}
            src="/icons/arcticons_ok-payment.svg"
            title="success"
            message="bank details have been updated"
            btnText="home"
            btnOnClick={() => setSuccessModal((prev) => !prev)}
            to="../../../dashboard"
            outsideOnClick={handleBack}
         />
      </Fragment>
   );
}
