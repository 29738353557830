import * as REGEX from 'utils/constants/regex';
import NumberFormat from 'react-number-format';
import { IoArrowBack } from "react-icons/io5";
import {useNavigate} from "react-router-dom";
import Input from 'components/shared/forms/input';
import Select from 'components/shared/forms/select';
import Button from 'components/shared/forms/button';
import FormGroup from 'components/shared/forms/form-group';
import PageTitle from 'components/shared/layout/headers/page-title';

export default function TransactionDetails({
   optionsObj,
   selectLabel,
   handleSubmit,
   state,
   dispatch,
   formValidation
}) {

   const navigate = useNavigate();

   const SelectOptions = optionsObj || [
      { name: 'food', icon: 'food.svg' },
      { name: 'payment', icon: 'atm_card.svg' },
      { name: 'books', icon: 'book_mark.svg' },
      { name: 'transportation', icon: 'transportation.svg' },
      { name: 'peer transfer', icon: 'gift.svg' },
   ];
   //styles
   
   const handleNumberFormat =(values, input, formattedInput) =>{
      const { value, formattedValue} = values;
      dispatch({
         type:'updateInput',
         payload:{ name: input, value: value}
      })
      dispatch({
         type:'updateInput',
         payload:{ name:formattedInput, value: formattedValue}
      })
   }

   return (
      <section className="mb-14 md:ml-32 lg:ml-48">
         <div className="container left pt-16 md:min-h-0">
         <IoArrowBack
         onClick={() =>  navigate("../../../dashboard/receive-payment/1")}
         className="fixed top-5 left-4 w-12 text-primary cursor-pointer text-4xl md:hidden md:w-8"
      />
            <PageTitle 
               variant='SECONDARY'
               title='transaction details'
               message='enter transaction details'
            />
            <img
               src="/icons/profile_icon.svg"
               alt="profile icon"
               className="filter-blue mb-10 block w-20 self-center"
            />
            <div className="self-stretch mb-20">
               <FormGroup className="mb-[34px]">
                  <NumberFormat 
                     customInput={Input} 
                     value={state.amount}
                     prefix={'₦'}
                     thousandSeparator={true} 
                     label="amount"
                     placeholder="₦"
                     errorMessage={{
                        requiredError: 'amount cannot be empty',
                        validityError: 'please enter a valid amount'
                     }}
                     isValid={REGEX.AMOUNT_VALIDATION.test(state.amount)}      
                     onValueChange={(values) =>handleNumberFormat(values, 'amount', 'formattedAmount')}
                  />
               </FormGroup>
               <Select
                  lineSelect={true}
                  value={state.paymentPurpose}
                  name="paymentPurpose"
                  label={selectLabel || 'purpose'}
                  dispatch={dispatch}
                  options={SelectOptions}
                  dropDownIcon={
                     <span className="font-xs font-semibold text-yellow-500">
                        select
                     </span>
                  }
               />
            </div>
            <Button size='WIDE' onClick={handleSubmit}>pay</Button>
         </div>
      </section>
   );
}
