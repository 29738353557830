import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import Button from "../forms/button";
import ModalContainer from "../modals/modal-container";

export default function IDCreatedModal({
   id,
   message,
   title,
   to,
   modalDisplay,
   toggleModal,
   handleClick,
   modalPosition,
}) {
   const [isCopied, setIsCopied] = useState(false);
   const toggleIsCopied = (value) => setIsCopied(value);

   const handleCopy = (e) => {
      e.preventDefault();
      navigator.clipboard.writeText(id);
      toggleIsCopied(true);

      setTimeout(() => {
         toggleIsCopied(false);
      }, 2000);
   };

   //styles
   const copiedAlertClass = `${
      isCopied ? "opacity-100" : "opacity-0"
   } absolute -bottom-16 text-lg right-1/2 translate-x-1/2 bg-secondary-darker z-50 text-neutral-200 px-6 py-2 rounded-sm transition-all duration-500 ease-in-out`;

   return (
      <ModalContainer
         area="THIN"
         modalDisplay={modalDisplay}
         modalPosition={modalPosition}
      >

         <div className="container justify-center py-16">
            <img
               src="/icons/profile_icon.svg"
               alt="profile icon"
               className="mb-10 block"
            />
            <p className="mb-10 whitespace-pre-wrap text-center text-xl font-medium text-secondary-darker">
               {message}
               <span className="block font-semibold text-primary">{title}</span>
            </p>
            <div className="relative mb-32">
               <div className="flex w-52 items-center justify-between border-b-2 border-primary px-4">
                  <p className="text-4xl font-semibold uppercase text-primary">
                     {id}
                  </p>
                  <button onClick={(e) => handleCopy(e)}>
                     <IoCopyOutline className="text-primary" fontSize={22} />
                  </button>
               </div>
               <span role="alert" className={copiedAlertClass}>
                  copied
               </span>
               <span className="absolute right-2 text-slate-500">copy</span>

            </div>
            {to ? (
               <Button size="WIDE" to={to}>
                  proceed
               </Button>
            ) : (
               <Button
                  size="WIDE"
                  onClick={() => (handleClick ? handleClick() : toggleModal())}
               >
                  proceed
               </Button>
            )}
         </div>
      </ModalContainer>
   );
}
