import WelcomeSlideParentWrapper from './slide-parent-wrapper';

export default function WelcomeSlideOne({ setScreen }) {
   return (
      <WelcomeSlideParentWrapper
         url="/icons/finance _ savings, saving, bank, piggy bank, coin, coins, interest, man, people 1Student.svg"
         title="Collect Payments"
         text="Collect payments from students using Ledga. No need for a POS or ATM card"
         screen={1}
         setScreen={setScreen}
      />
   );
}
