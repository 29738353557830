import { Navigate } from 'react-router-dom';
import Welcome from 'pages/welcome';
import SetUp from 'pages/setup/setup-page';
import Dashboard from 'pages/dashboard/dashboard-nav';
import DashboardHome from 'pages/dashboard/home/dashboard-homepage';
import ReceivePayment from 'pages/dashboard/receive-payment/receive-payment';
import PayoutRequest from 'pages/dashboard/payout-page/payout-request';
import Requests from 'pages/dashboard/requests/requests-page';
import Transactions from 'pages/dashboard/transactions/transactions-page';
import Settings from 'pages/dashboard/settings/settings-page';
import SetBankDetailsModal from 'components/dashboard/settings/bank-details/set-bank-details';
import ChangePin from 'components/dashboard/settings/change-pin/change-pin';
import ContactUs from 'components/dashboard/settings/contact-us/contact-us';
import ResetPassword from 'components/dashboard/settings/reset-password/reset-password';
import { getToken } from 'utils/lib/storage';

export const ProtectedRoute = ({ children }) => {
   const user = getToken();
   if (!user) {
      // user is not authenticated
      return <Navigate to="/signin" />;
   }
   return children;
};

export const protectedRoutes = [
   {
      path: 'welcome',
      element: (
         <ProtectedRoute>
            <Welcome />
         </ProtectedRoute>
      ),
   },
   {
      path: 'setup',
      element: (
         <ProtectedRoute>
            <SetUp />
         </ProtectedRoute>
      ),
   },
   {
      path: 'dashboard',
      element: (
         <ProtectedRoute>
            <Dashboard />
         </ProtectedRoute>
      ),
      children: [
         {
            index: true,
            element: <DashboardHome />,
         },
         { path: 'transactions', element: <Transactions /> },
         { path: 'requests', element: <Requests /> },
         { path: 'receive-payment/:step', element: <ReceivePayment /> },
         { path: 'payout-request', element: <PayoutRequest /> },
         {
            path: 'settings',
            children: [
               { index: true, element: <Settings /> },
               { path: 'change-pin', element: <ChangePin /> },
               { path: 'bank-details', element: <SetBankDetailsModal /> },
               { path: 'reset-password', element: <ResetPassword /> },
               { path: 'contact-us', element: <ContactUs /> },
            ],
         },
      ],
   },
];
