import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import ModalContainer from "components/shared/modals/modal-container";
import PageTitle from "components/shared/layout/headers/page-title";
import FormGroup from "components/shared/forms/form-group";
import ArrowBack from "components/shared/layout/nav/arrow-back";

export default function BankDetailsDisplay({
   modalDisplay,
   setModalDisplay,
   bankDetails,
   handleNext,
}) {
   const { accountName, accountNumber, bankName } = bankDetails;
   return (
      <ModalContainer
         modalDisplay={modalDisplay}
         toggleModal={() => setModalDisplay((prev) => !prev)}>
         <ArrowBack handleBack={() => setModalDisplay(false)} />
         <div className="container pt-32 md:py-16">
            <PageTitle
               variant="SECONDARY"
               title="Request Payout"
               message="Confirm account payment should be made to"
               toggleModal={() => setModalDisplay((prev) => !prev)}
            />
            <FormGroup className="mb-4 self-stretch">
               <FormGroup className="mb-12">
                  <Input
                     disabled={true}
                     variant="LINE"
                     value={bankName}
                     icon={accountNumber}
                     label="bank account"
                     iconPosition="RIGHT"
                     readOnly
                  />
               </FormGroup>
               <Input
                  disabled={true}
                  variant="LINE"
                  value={accountName}
                  label="account name"
                  readOnly
               />
            </FormGroup>
            <div className="mb-10">
               <Button
                  variant="TERTIARY"
                  color="YELLOW_TEXT"
                  to="../../dashboard/settings/bank-details">
                  change payout account
               </Button>
            </div>
            <Button size="WIDE" onClick={handleNext}>
               confirm
            </Button>
         </div>
      </ModalContainer>
   );
}
