export default function CardItem({ className, children }) {
   const cardClass = `
        ${className}
        p-5 
        shadow-bottom
        w-full
        p-2
        rounded-xl
        
    `;
   return <div className={`${cardClass}`}>{children}</div>;
}
