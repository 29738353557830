import { useState } from 'react';

export default function Input({
   typeTwo,
   className,
   label,
   value,
   type = 'text',
   ...rest
}) {
   //TODO: Refactor form input component to cover this use case

   const [isActive, setIsActive] = useState(false);
   const toggleIsActive = () => setIsActive((prev) => !prev);

   //styles
   const buttonStyle = `absolute right-0 bottom-2  text-blue-600 text-sm sm:bottom-3 sm:text-base`;

   const labelStyle = `text-xs sm:text-sm md:text-base`;

   const inputStyle = `${className} 
      mr-2 border-slate-600 border-b-2 outline-none focus:border-blue-600 
      w-full py-2 text-secondary-darker text-md dark:bg-transparent dark:text-black dark:border-neutral-600
   `;

   return (
      <label className="relative block w-full text-slate-400 sm:mb-4 md:mb-6">
         <span className={labelStyle}>{label}</span>
         <input
            value={value}
            type={type}
            className={inputStyle}
            readOnly={!isActive}
            {...rest}
         />

         {isActive ? (
            <button
               className={buttonStyle}
               type="submit"
               onClick={toggleIsActive}
            >
               save
            </button>
         ) : (
            <button
               className={buttonStyle}
               type="button"
               onClick={toggleIsActive}
            >
               edit
            </button>
         )}
      </label>
   );
}
