import { useState } from "react";
import CardItem from "./card-item";
import SummaryTab from "./summary-tab";
import SummaryButton from "./summary-btn";

export default function TransactionSummary({ summaryData }) {
   const [isActive, setIsActive] = useState("day");
   const activeData = summaryData[isActive];

   const itemClass = `
      bg-white 
      px-4 py-5 
      mx-auto mb-10 
      text-xs font-medium text-gray-500 
      sm:text-sm 
      sm:px-5 sm:py-6 
      md:text-base 
      md:px-6 md:py-8 
      lg:text-lg
      lg:px-7 lg:py-9 
   `;

   const titleClass = `
      text-sm font-semibold 
      sm:text-base 
      md:text-lg 
      lg:text-xl
   `;

   return (
      <section className="mx-auto flex w-5/6">
         <CardItem className={itemClass}>
            <div className="mb-4 flex justify-between">
               <h3 className={titleClass}>Summary</h3>
               <div className="flex">
                  <SummaryButton
                     isActive={isActive}
                     setIsActive={setIsActive}
                     name="day"
                     label="today"
                  />
                  <SummaryButton
                     isActive={isActive}
                     setIsActive={setIsActive}
                     name="week"
                     label="this week"
                  />
                  <SummaryButton
                     isActive={isActive}
                     setIsActive={setIsActive}
                     name="month"
                     label="this month"
                  />
               </div>
            </div>

            <SummaryTab
               label="total transfers"
               value={activeData.transfers}
               valuePct={activeData.transfersPct}
            />

            <SummaryTab
               label="total payouts"
               value={activeData.payouts}
               valuePct={activeData.payoutsPct}
            />

            <SummaryTab
               label="transaction volume"
               value={activeData.total}
               valuePct="100%"
            />
         </CardItem>
      </section>
   );
}
