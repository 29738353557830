export const getTransferDetails = (transaction) => {
   switch (transaction.transaction_type) {
      case "funding":
         return {
            sender: {
               accountNumber: transaction.paid_from.accountNumber,
               bankName: transaction.paid_from.bankName,
            },
            recipient: {
               id: transaction.from,
            },
         };

      case "payout":
         return {
            sender: {
               id: transaction?.from,
            },
            recipient: {
               accountNumber: transaction?.paid_to?.accountNumber,
               bankName: transaction?.paid_to?.bankName,
            },
         };

      case "transfer": {
         return {
            sender: {
               id: transaction.from,
            },
            recipient: {
               id: transaction.to,
            },
         };
      }

      default:
         return;
   }
};
