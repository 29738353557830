import Input from "components/shared/forms/input";
import Button from "components/shared/forms/button";
import ArrowBack from "components/shared/layout/nav/arrow-back";
import SplashScreen from "components/shared/layout/splash-screen";
import PageTitle from "components/shared/layout/headers/page-title";
import SearchInput from "components/shared/forms/search-input";
import Spinner from "components/shared/feedbacks/spinner";
import useBankDetails from "api/hooks/useBankDetails";

export default function BankDetailsForm({
   setup,
   state,
   dispatch,
   handleSubmit,
   btnText,
   setScreen,
   handleBack,
   formValidation,
   handleError,
}) {
   const {
      bankNames,
      bankDataLoading,
      getNubanLoading,
      handleChange,
      handleBankSelect,
   } = useBankDetails(state, dispatch, handleError);

   return (
      <section>
         {!setup && <ArrowBack handleBack={handleBack} />}
         <div className="mx-auto flex">
            {setup && <SplashScreen />}
            <div className="container my-16">
               {setup && (
                  <ArrowBack
                     variant="TERTIARY"
                     handleBack={() => setScreen((prev) => prev - 1)}
                  />
               )}
               <PageTitle
                  variant="TERTIARY"
                  title="bank details"
                  message="Setup your account for receiving payouts and more stuffs"
               />
               <form className="mb-8 self-stretch">
                  <SearchInput
                     value={state.bankName}
                     name="bankName"
                     label="bank name"
                     iconPosition="RIGHT"
                     searchArr={bankNames}
                     dispatch={dispatch}
                     handleSelect={handleBankSelect}
                     errorMessage={{
                        requiredError: "bank name cannot be empty",
                        validityError: "please enter a valid bank name",
                     }}
                     isValid={formValidation.bankName}
                     icon={bankDataLoading && <Spinner />}
                  />
                  <Input
                     value={state.accountNumber}
                     name="accountNumber"
                     label="account number"
                     onChange={handleChange}
                     errorMessage={{
                        requiredError: "account number must be 10 digits long",
                        validityError: "please enter a valid account number",
                     }}
                     isValid={formValidation.accountNumber}
                  />
                  <Input
                     label="account name"
                     iconPosition="RIGHT"
                     icon={getNubanLoading && <Spinner color="GREY" />}
                     value={state.accountName}
                     disabled
                  />
                  {setup && (
                     <Input
                        value={state.bvn}
                        name="bvn"
                        label="bvn"
                        onChange={handleChange}
                        errorMessage={{
                           requiredError:
                              "bank must be at least 11 digits long",
                           validityError: "please enter a valid bvn",
                        }}
                        isValid={formValidation.bvn}
                     />
                  )}
               </form>
               <Button size="WIDE" onClick={handleSubmit}>
                  {btnText}
               </Button>
            </div>
         </div>
      </section>
   );
}
