import { MdOutlineCancel } from "react-icons/md";
import { useState } from "react";
import useRequest from "utils/hooks/useRequest";
import LoadingModal from "components/shared/feedbacks/loading-modal";
import SuccessFeedbackModal from "components/shared/feedbacks/success-modal";
import FailModal from "components/shared/feedbacks/failure-modal";
import useAllRequests from "api/hooks/useAllRequests";
import { cancelPayoutRequest } from "api/services/payments.service";
import TransactionDetails from "components/shared/modals/transaction-details";

export default function RequestDetails({
   requestId,
   setActiveModal,
   modalDisplay,
   toggleModal,
   handleBack,
   sender,
   recipient,
   amount,
   status,
}) {
   const [successModal, setSuccessModal] = useState(false);
   const [errorModal, setErrorModal] = useState("");

   //feedback states
   const [successIcon, setSuccessIcon] = useState("");
   const [successMessage, setSuccessMessage] = useState("");
   const [successTitle, setSuccessTitle] = useState("");
   const [errorMessage, setErrorMessage] = useState("");

   //query functions
   const { mutateRequests } = useAllRequests();

   const { queryFn: cancelPayoutFn, loading: cancelLoading } =
      useRequest(cancelPayoutRequest);

   //handle functions
   const handleCancel = () => {
      setActiveModal("");
      const queryObj = { transaction_id: requestId };
      cancelPayoutFn(queryObj, {
         onSuccess: () => {
            mutateRequests();
            setSuccessTitle("payout canceled");
            setSuccessMessage("payout request cancelled");
            setSuccessIcon(<MdOutlineCancel className="text-red-600" />);
            setSuccessModal(true);
         },

         onError: () => {
            setErrorModal(true);
            setErrorMessage(
               `payout request could not be canceled\nplease check your network settings and try again`,
            );
         },
      });
   };

   return (
      <>
         <TransactionDetails
            modalDisplay={modalDisplay}
            toggleModal={toggleModal}
            handleBack={handleBack}
            title="payout request history"
            sender={sender}
            recipient={recipient}
            requestId={requestId}
            amount={amount}
            status={status}
         >
            {status === "pending" && (
               <div className="mx-auto my-10 flex w-3/4 justify-center text-xl md:text-2xl">
                  <div
                     className="flex cursor-pointer flex-col items-center text-red-600"
                     onClick={handleCancel}
                  >
                     <MdOutlineCancel className="text-5xl md:text-7xl" />
                     <p>cancel request</p>
                  </div>
               </div>
            )}
         </TransactionDetails>
         <LoadingModal
            modalDisplay={cancelLoading}
            message="please wait while payout request is being processed"
         />
         <FailModal
            modalDisplay={errorModal}
            title="something went wrong"
            message={errorMessage}
            btnText="try again"
            toggleModal={() => setErrorModal(false)}
            btnOnClick={() => setErrorModal(false)}
         />
         <SuccessFeedbackModal
            bg="WHITE"
            modalDisplay={successModal}
            icon={successIcon}
            title={successTitle}
            message={successMessage}
            btnText="ok"
            btnOnClick={() => setSuccessModal(false)}
            toggleModal={() => setSuccessModal((prev) => !prev)}
         />
      </>
   );
}
