import Button from '../forms/button';
import { MdOutlineCancel } from 'react-icons/md';
import ModalContainer from '../modals/modal-container';

export default function SuccessFeedbackModal({
   src,
   icon,
   title,
   message,
   btnText,
   to,
   href,
   btnOnClick,
   outsideOnClick,
   modalDisplay,
   modalPosition,
   toggleModal,
   children,
   cancelBtn,
   bg,
}) {
   

   if (!title) {
      return <></>;
   }

   return (
      <ModalContainer
         area='MEDIUM'
         role="alert"
         color={bg === 'WHITE' ? bg : 'BLUE'}
         modalDisplay={modalDisplay}
         toggleModal={toggleModal}
         position={modalPosition}
         outsideOnClick={outsideOnClick}
      >
         {cancelBtn &&
            <MdOutlineCancel
               fontSize={50}
               onClick={toggleModal}
               className="fixed top-6 right-6 hidden cursor-pointer md:block "
            />}
         <div className="container py-20 justify-center">
            {icon ? (
               <div className="flex justify-center text-7xl mb-6 md:text-8xl">{icon}</div>
            ) : (
               <img
                  src={src || '/icons/carbon_task-complete.svg'}
                  alt="Icon"
                  className="mx-auto mb-4 w-28"
               />
            )}

            <h1 className="text-2xl font-bold capitalize">{title}</h1>
            <p className="mt-1 sm:text-base md:text-lg text-center">{message}</p>
            
            {children &&
               <div className='whitespace-pre-wrap'>
                  {children}
               </div>}

            <div className="mt-10"> 
               {btnText && (
                  <Button
                     to={to}
                     href={href}
                     onClick={btnOnClick}
                     size='WIDE'
                     color={bg === 'WHITE' ? 'BLUE' : 'WHITE'}
                  >
                     {btnText}
                  </Button>
               )}
            </div>
         </div>
      </ModalContainer>
   );
}
