import { BsFilter } from "react-icons/bs";
import RequestsTableMonths from "./table-months";
import TableRow from "components/shared/tables/table-row";
import TableCol from "components/shared/tables/table-col";
import Spinner from "components/shared/feedbacks/spinner";
import FailModal from "components/shared/feedbacks/failure-modal";

export default function RequestsTable({
   requestsData,
   requestsError,
   mutateRequests,
   requestsLoading,
   toggleRangeModal,
}) {
   //styles
   const wrapper = `
      mx-auto 
      w-5/6 
      max-w-sm 
      text-base 
      sm:max-w-lg 
      sm:text-lg  
      md:max-w-3xl 
      md:text-xl
   `;
   const tableHeader = `
      text-secondary-lighter 
      text-sm 
      font-medium
      mb-6 
      w-full 
      mx-auto 
      max-w-screen-md
   `;
   const yearClass = `
      text-2xl 
      font-semibold 
      text-secondary-darker 
      sm:pl-1 
      md:text-3xl
   `;

   return (
      <div className={wrapper}>
         <div className={tableHeader}>
            <TableRow
               gridTemplateCols="grid-cols-3 sm:grid-cols-4"
               rowTypeHeader={true}>
               <TableCol className="cursor-pointer">
                  <BsFilter fontSize={25} onClick={toggleRangeModal} />
                  <p className="ml-2 hidden sm:block">id</p>
                  <p className="ml-2 sm:hidden">date</p>
               </TableCol>
               <TableCol className="hidden justify-center sm:flex">
                  date
               </TableCol>
               <TableCol className="justify-center">amount</TableCol>
               <TableCol className="mr-3 justify-end">status</TableCol>
            </TableRow>
         </div>

         {requestsError ? (
            <FailModal
               modalDisplay={requestsError}
               btnOnClick={mutateRequests}
            />
         ) : requestsLoading ? (
            <div className="my-32 flex items-center justify-center">
               <Spinner color="GREY" size="MEDIUM" />
            </div>
         ) : (
            Object.keys(requestsData).map((year) => {
               return (
                  <section className="mb-10" key={year}>
                     <h2 className={yearClass}>{year}</h2>
                     {requestsData[year].map((month) => {
                        return (
                           <RequestsTableMonths
                              month={month}
                              key={Object.keys(month)}
                           />
                        );
                     })}
                  </section>
               );
            })
         )}
      </div>
   );
}
