import { Link } from "react-router-dom";

export default function NavItem({ icon, label, href, btnOnClick }) {
   const className =
      "flex flex-col items-center text-sm text-neutral-500 p-4 focus:text-blue-600 focus:font-bold focus:border-blue-200 border-b border-neutral-100 hover:border-neutral-300 dark:text-neutral-200";

   if (btnOnClick) {
      return (
         <button onClick={btnOnClick} className={className}>
            {icon}
            <span>{label}</span>
         </button>
      );
   } else if (href) {
      return (
         <Link to={href} className={className}>
            {icon}
            <span>{label}</span>
         </Link>
      );
   }
}
