export default function SummaryTab({ value, valuePct, label }) {
   const getColor = (label, property) => {
      const COLOR_MAPS = {
         bg500: { r: "bg-red-500", g: "bg-green-500", b: "bg-blue-500" },
         bg300: { r: "bg-red-300", g: "bg-green-300", b: "bg-blue-300" },
         txt500: { r: "text-red-500", g: "text-green-500", b: "text-blue-500" },
      };

      switch (label) {
         case "total payouts": {
            return COLOR_MAPS[property]["r"];
         }
         case "total transfers": {
            return COLOR_MAPS[property]["g"];
         }
         default: {
            return COLOR_MAPS[property]["b"];
         }
      }
   };

   const textClass = `${getColor(label, "txt500")} text-right`;

   const bgClass = `${getColor(label, "bg300")} 
         col-span-2 h-2 rounded-lg sm:h-3 md:h4`;

   const pctClass = `${getColor(label, "bg500")} 
         h-full rounded-lg transition-all duration-500`;

   return (
      <div className="mb-6 grid grid-cols-2">
         <p>{label}</p>
         <p className={textClass}>{value}</p>
         <div className={bgClass}>
            <div style={{ width: valuePct }} className={pctClass}></div>
         </div>
      </div>
   );
}
